import {ApiService} from "./ApiService";

export class BoardService extends ApiService {

	getUserBoards(tenantId, success, failure) {
		this.fetch(`${this.uri}/api/userboards?tenantid=${tenantId}`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getBoards(tenantId, success, failure) {
		this.fetch(`${this.uri}/api/tenants/${tenantId}/boards`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	newBoard(board, success, failure) {
		this.fetch(`${this.uri}/api/boards`, {
			method: 'POST',
			body: JSON.stringify(board)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	saveBoard(board, success, failure) {
		this.fetch(`${this.uri}/api/boards`, {
			method: 'PUT',
			body: JSON.stringify(board)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	deleteBoardById(id, success, failure) {
		this.fetch(`${this.uri}/api/boards/${id}`, {
			method: 'DELETE'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getMetrics(boardid, success, failure) {
		this.fetch(`${this.uri}/api/boards/${boardid}/metrics`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getBoardAndMetrics(boardid, success, failure) {
		this.fetch(`${this.uri}/api/boardandmetrics/${boardid}`, {
			method: 'GET'
		}).then(response => {
			// Returns a new board and metrics
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	moveMetric(boardId, sourceIndex, targetIndex, success, failure) {
		this.fetch(`${this.uri}/api/boards/${boardId}/movemetric`, {
			method: 'PATCH',
			body: JSON.stringify({sourceIndex: sourceIndex, targetIndex: targetIndex})
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	moveBoard(boardId, sourceIndex, targetIndex, success, failure) {
		this.fetch(`${this.uri}/api/boards/${boardId}/moveboard`, {
			method: 'PATCH',
			body: JSON.stringify({sourceIndex: sourceIndex, targetIndex: targetIndex})
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			setTimeout(failure(error.message), 100);
		});
	}

	updateUserBoard(userboard, success, failure) {
		console.log( JSON.stringify( userboard ) );
		this.fetch(`${this.uri}/api/userboards/${userboard.boardId}`,{
			method: 'PATCH',
			body: JSON.stringify(userboard)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			setTimeout(failure(error.message), 100);
		});
	}

}