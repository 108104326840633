import React, {Component} from 'react'
import './ProgressBar.css'

export default class ProgressBar extends Component {

	state = {};

	render() {
		let stateClass = 'ProgressBar';
		if (this.props.status === 'error') stateClass = 'ProgressBar Error';
		return (
			<div className={stateClass}>
				{this.props.status === 'error' ? <div>{this.props.message}</div> : <div className="Progress" style={{width: this.props.progress + '%'}}/>}
			</div>
		)
	}

}
