import React, {PureComponent} from 'react';
import moment from 'moment';
import {Button, Header, Segment} from 'semantic-ui-react'
import {CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {graphService} from "../Api";

// This is how many refresh counts
// Currently this is roughly 5 seconds
const COUNT = 1;

const LINE_TYPES = {
	line: 'linear',
	step: 'stepAfter',
	curve: 'basis',
	dot: 'linear'
};

const dateFormat = 'YYYY-MM-DD';
const timeFormat = 'kk:mm:ss';

const tooltipTimeFormatter = t => "Time: " + moment(t).format(dateFormat + ' ' + timeFormat);
const tooltipValueFormatter = (value, name, props) => props.payload.labels[props.dataKey];

// const style = {border: '1px solid red'};

export default class GraphMetric extends React.Component {

	count = COUNT;

	state = {
		graph: {series: []}
	};

	componentDidMount() {
		this.loadGraphSeries();
	}

	loadGraphSeries = () => {
		graphService.getGraphSeries(this.props.metric.configid, (graph) => {
			this.setState({graph: graph});
		}, (message) => {
			console.log("graph.series.error=" + message);
		});
	};

	downloadCsv = () => {
		let uri = graphService.uri + "/api/graph/csv/" + this.props.metric.configid;
		console.log("Download: " + uri);
		graphService.getGraphCsv(this.props.metric.configid, (response) => {
			graphService.saveAs(response, this.props.metric.name + " Graph.csv")
		}, (error) => {
			console.log("ERROR: " + JSON.stringify(error));
		});
	};

	// format(timestamp) {
	// 	return new Date(timestamp).toLocaleDateString('en-us', {formatMatcher: 'basic', year: '4-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true});
	// }

	render() {
		this.count++;
		if (this.count > COUNT) {
			setTimeout(this.loadGraphSeries, 100);
			this.count = 0;
		}

		const w = this.state.graph.width || '100%';
		const h = this.state.graph.height || '200';
		const width = w.endsWith('%') ? w : parseInt(w);
		const height = h.endsWith('%') ? h : parseInt(h);

		const timeAxisLimit1 = this.state.graph.timeaxislimit1 === undefined ? 'auto' : this.state.graph.timeaxislimit1;
		const timeAxisLimit2 = this.state.graph.timeaxislimit2 === undefined ? 'auto' : this.state.graph.timeaxislimit2;
		const valueAxisLimit1 = this.state.graph.valueaxislimit1 === undefined ? 'auto' : this.state.graph.valueaxislimit1;
		const valueAxisLimit2 = this.state.graph.valueaxislimit2 === undefined ? 'auto' : this.state.graph.valueaxislimit2;

		//console.log("name=" + this.props.metric.name + " w=" + width + ",h=" + height + ",tmin=" + this.state.graph.timeaxislimit1 + ",tmax=" + this.state.graph.timeaxislimit2, ",vmin=" + this.state.graph.valueaxislimit1 + ",vmax=" + this.state.graph.valueaxislimit2);
		//console.log("name=" + this.props.metric.name + " w=" + width + ",h=" + height + ",tmin=" + timeAxisLimit1 + ",tmax=" + timeAxisLimit2, ",vmin=" + valueAxisLimit1 + ",vmax=" + valueAxisLimit2);

		const lines = this.state.graph.series.map((series) => {
			//console.log( JSON.stringify( series ));
			let linetype = LINE_TYPES[series.linetype] || 'linear';
			let linecolor = series.linecolor || '#208080';
			let linewidth = series.linewidth || '';
			let strokeDash = "";
			// The default dot radius is 3
			let dot = series.linetype === 'dot' ? {r: linewidth, fill: linecolor} : false;
			if (dot) strokeDash = "0 1";
			return <Line key={series.key} dataKey={series.key} name={series.name} type={linetype} stroke={linecolor} strokeWidth={linewidth} strokeDasharray={strokeDash} dot={dot} isAnimationActive={false} connectNulls={false}/>
		});

		const min = Math.min(timeAxisLimit1, timeAxisLimit2);
		const range = Math.max(timeAxisLimit1, timeAxisLimit2) - Math.min(timeAxisLimit1, timeAxisLimit2);
		const ticks = [min, min + range * 0.25, min + range * 0.5, min + range * 0.75, min + range,]

		return (
			<Segment>
				<Header>{this.props.metric.name}
					<Button compact icon='table' size='mini' floated='right' onClick={this.downloadCsv}/>
				</Header>
				<ResponsiveContainer width={width} height={height}>
					<LineChart data={this.state.graph.data} margin={{top: 10, right: 70, bottom: 10, left: 10}}>
						<CartesianGrid strokeDasharray="2 4"/>
						<XAxis dataKey='t' type='number' domain={[timeAxisLimit1, timeAxisLimit2]} tick={<CustomizedAxisTick/>} ticks={ticks}/>
						<YAxis type='number' domain={[valueAxisLimit1, valueAxisLimit2]} allowDataOverflow={true}>
							<Label value={this.state.graph.valueaxislabel} angle={90}/>
						</YAxis>
						<Tooltip isAnimationActive={false} labelFormatter={tooltipTimeFormatter} formatter={tooltipValueFormatter}/>
						<Legend align='center' verticalAlign="top"/>
						{lines}
					</LineChart>
				</ResponsiveContainer>
			</Segment>
		)

	}

}

class CustomizedAxisTick extends PureComponent {
	render() {
		const {
			x, y, payload
		} = this.props;

		const date = moment(payload.value).format(dateFormat);
		const time = moment(payload.value).format(timeFormat);

		return (
			<g transform={`translate(${x},${y})`}>
				<text x={0} y={0} dy='0.8em' textAnchor="middle" fill="#666">{date}</text>
				<text x={0} y={0} dy='1.8em' textAnchor="middle" fill="#666">{time}</text>
			</g>
		);
	}
}
