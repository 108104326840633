import {ApiService} from "./ApiService";

export class StationService extends ApiService {

	getStations(success, failure) {
		this.fetch(`${this.uri}/api/stations`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	newStation(station, success, failure) {
		this.fetch(`${this.uri}/api/stations`, {
			method: 'POST',
			body: JSON.stringify(station)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	saveStation(station, success, failure) {
		this.fetch(`${this.uri}/api/stations`, {
			method: 'PUT',
			body: JSON.stringify(station)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	deleteStationById(id, success, failure) {
		this.fetch(`${this.uri}/api/stations/${id}`, {
			method: 'DELETE'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getDevices(stationid, success, failure) {
		this.fetch(`${this.uri}/api/stations/${stationid}/devices`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	importDevices(stationid, file, progress, success, failure) {
		this.upload(`${this.uri}/api/stations/${stationid}/devices/import`, file, progress, success, failure).then(response => {
			//console.log("response=" + JSON.stringify(response));
			success(response)
		}).catch(error => {
			failure(error)
		});
	}

	moveDevice(stationId, sourceIndex, targetIndex, success, failure) {
		this.fetch(`${this.uri}/api/stations/${stationId}/movedevice`, {
			method: 'PUT',
			body: JSON.stringify({sourceIndex: sourceIndex, targetIndex: targetIndex})
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	rotateAccessKey(stationId, key, success, failure) {
		this.fetch(`${this.uri}/api/stations/${stationId}/rotateaccesskey`, {
			method: 'PUT',
			body: JSON.stringify({key: key})
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

}