import React, {Component} from 'react'
import {Menu} from 'semantic-ui-react'
import {ANIMATION_DURATION, BOARD_ICON, STATION_ICON, USER_ICON, TENANT_ICON} from "../config";
import * as User from "../User";
import * as Role from "../Role";

export default class SuperMenu extends Component {

	handleBoardManagement = () => {
		setTimeout(this.props.history.push, ANIMATION_DURATION, '/manage/boards');
		this.props.onMenuClick();
	};

	handleManageStations = () => {
		this.props.onMenuClick();
		setTimeout(this.props.history.push, ANIMATION_DURATION, '/manage/stations');
	};

	handleManageUsers = () => {
		this.props.onMenuClick();
		setTimeout(this.props.history.push, ANIMATION_DURATION, '/manage/users');
	};

	handleManageTenants = () => {
		this.props.onMenuClick();
		setTimeout(this.props.history.push, ANIMATION_DURATION, '/manage/tenants');
	};

	render() {
		const isSuperUser = User.hasRole(this.props.user, Role.SUPER);

		let menu = [];
		menu.push(<Menu.Item
			icon={BOARD_ICON}
			key='board-management'
			content='Board Management'
			onClick={this.handleBoardManagement}
		/>);

		if (isSuperUser) {
			menu.push(
				<Menu.Item
					icon={STATION_ICON}
					key='manage-station'
					content='Station Management'
					onClick={this.handleManageStations}
				/>,
				<Menu.Item
					icon={USER_ICON}
					key='manage-user'
					content='User Management'
					onClick={this.handleManageUsers}
				/>,
				<Menu.Item
					icon={TENANT_ICON}
					key='manage-tenant'
					content='Client Management'
					onClick={this.handleManageTenants}
				/>
			);
		}

		return menu;
	}
}
