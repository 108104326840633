import React from 'react';
import {Button, Form, Grid, Header, Icon, Message, Modal} from 'semantic-ui-react'
import {deviceService, lookupService, stationService, tenantService} from "../Api";
import {CANCEL_ICON, DEVICE_ICON, DEVICE_READ_ONLY_ICON, SAVE_ICON} from '../config';

export default class DeviceManagement extends React.Component {

	state = {
		editing: false,
		device: {}
	};

	handleFormOpen = () => this.setState({device: {id: '', name: '', link: {}, station: this.props.station}, editing: true});

	handleFormClose = () => this.setState({editing: false});

	handleEditDevice = (device) => {
		this.setState({device: device, editing: true});
	};

	handleSaveDevice = (device, failure) => {
		if (device.id === '') {
			this.handleCreateDevice(device, failure)
		} else {
			this.handleUpdateDevice(device, failure)
		}
	};

	handleCreateDevice = (device, failure) => {
		deviceService.newDevice(device, () => {
			this.handleFormClose();
		}, () => {
			failure();
		});
	};

	handleUpdateDevice = (device, failure) => {
		deviceService.saveDevice(device, () => {
			this.handleFormClose();
		}, () => {
			failure();
		});
	};

	handleCancelDevice = () => {
		this.handleFormClose();
	};

	render() {
		if (this.props.station.id === undefined) return (
			<div/>
		);

		if (this.state.editing) {
			return (
				<DeviceForm device={this.state.device} onSaveDevice={this.handleSaveDevice} onCancelDevice={this.handleCancelDevice}/>
			);
		} else {
			return (
				<DeviceList
					station={this.props.station}
					onNewDevice={this.handleFormOpen}
					onEditDevice={this.handleEditDevice}
				/>
			);
		}
	}
}

class DeviceList extends React.Component {

	state = {
		devices: []
	};

	componentDidMount() {
		this.loadDevices();
		this.refreshTimer = setInterval(this.loadDevices, 2000);
	}

	componentWillUnmount() {
		clearInterval(this.refreshTimer);
	}

	loadDevices = () => {
		stationService.getDevices(this.props.station.id, (devices) => {
			this.setState({devices: devices});
		}, (message) => {
			console.log(message)
		})
	};

	handleNewDevice = () => {
		this.props.onNewDevice()
	};

	handleEditDevice = (device) => {
		this.props.onEditDevice(device)
	};

	handleDeleteDevice = (id) => {
		deviceService.deleteDeviceById(id, () => {
			this.loadDevices();
		}, (message) => {
			console.log(message);
		});
	};

	handleMoveDevice = (sourceIndex, targetIndex) => {
		stationService.moveDevice(this.props.station.id, sourceIndex, targetIndex, (devices) => {
			this.setState({devices: devices})
		}, (message) => {
			console.log(message)
		})
	};

	render() {
		return (
			<Grid columns={3}>
				<Grid.Row>
					<Grid.Column width={16}>
						<Header>Devices</Header>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						<Button icon='add' fluid onClick={this.handleNewDevice}/>
					</Grid.Column>
				</Grid.Row>
				{
					this.state.devices.map((device, index) => (
							// Probably because the key is the same, device property changes are not propagated
							// Adding the name to the key fixed the issue, but what about other properties?
							// Do I need to create a state hash to be put in the key?
							// Using the map index also does not work because it does not change.
							<Device key={device.id} idx={index} device={device} onEditDevice={this.handleEditDevice} onDeleteDevice={this.handleDeleteDevice}
											onMoveDevice={this.handleMoveDevice}/>
						)
					)
				}
				<Grid.Row>
					<Grid.Column width={16}>
						<Button icon='add' fluid onClick={this.handleNewDevice}/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		)
	}

}

class Device extends React.Component {

	state = {
		hover: false
	};

	handleEditDevice = () => this.props.onEditDevice(this.props.device);

	handleDeleteDevice = () => this.props.onDeleteDevice(this.props.device.id);

	handleDragStart = (event) => {
		event.dataTransfer.effectAllowed = "move";
		event.dataTransfer.setData("text/plain", this.props.idx);
	};

	handleDragEnter = (event) => {
		event.preventDefault();
		//let sourceIndex = parseInt( event.dataTransfer.getData("text/plain") );
		//this.setState({hover: sourceIndex !== this.props.idx});
		this.setState({hover: true});
	};

	handleDragOver = (event) => {
		event.preventDefault();
		//let sourceIndex = event.target.props.idx;
		//console.log("source=" + sourceIndex + " target=" + this.props.idx);
		//this.setState({hover: sourceIndex !== this.props.idx});
	};

	handleDragLeave = (event) => {
		//event.preventDefault();
		//console.log("Drag leave: " + event);
		this.setState({hover: false});
	};

	handleDrop = (event) => {
		event.preventDefault();
		this.setState({hover: false});
		let sourceIndex = parseInt(event.dataTransfer.getData("text/plain"), 10);
		let targetIndex = this.props.idx;
		if (sourceIndex === targetIndex) return;

		// TODO This works well enough to call the API and move the device
		console.log("Device " + sourceIndex + " dropped on: " + this.props.idx);
		this.props.onMoveDevice(sourceIndex, targetIndex);
	};

	render() {
		const link = this.props.device.link !== null;
		const icon = this.props.device.readOnly ? DEVICE_READ_ONLY_ICON : DEVICE_ICON;

		return (
			<Grid.Row draggable onDragStart={this.handleDragStart} onDragEnter={this.handleDragEnter} onDragOver={this.handleDragOver} onDragLeave={this.handleDragLeave} onDrop={this.handleDrop}>
				<Grid.Column width={5}>
					<Header style={{cursor: 'pointer'}} onClick={this.handleEditDevice}>
						<Icon name={icon}/>
						{'[' + this.props.device.tag + '] '}{this.props.device.name}
					</Header>
				</Grid.Column>
				<Grid.Column width={1} textAlign='right'>
					{this.props.device.deviceType.name}
				</Grid.Column>
				<Grid.Column width={1} textAlign='right'>
					{this.props.device.address}
				</Grid.Column>


				{link &&
				<Grid.Column width={4}>
					{this.props.device.link.station.name} &gt; {this.props.device.link.name}
				</Grid.Column>
				}
				{!link &&
				<Grid.Column width={4}/>
				}

				<Grid.Column width={2} textAlign='right'>
					{this.props.device.value}
				</Grid.Column>
				<Grid.Column width={2} textAlign='left'>
					{this.props.device.unit}
				</Grid.Column>
				<Grid.Column textAlign='right' width={1}>
					<DeleteDeviceDialog device={this.props.device} onDeleteDevice={this.handleDeleteDevice}/>
				</Grid.Column>
			</Grid.Row>
		)
	}

}

class DeleteDeviceDialog extends React.Component {

	state = {open: false};

	handleOpen = () => this.setState({open: true});

	handleClose = () => this.setState({open: false});

	handleDelete = () => {
		this.handleClose();
		this.props.onDeleteDevice();
	};

	render() {
		return (
			<Modal size={'mini'} centered={false} open={this.state.open} onClose={this.handleClose} trigger={<Icon name={'trash'} size={'large'} link onClick={this.handleOpen}/>}>
				<Modal.Header>Delete Device</Modal.Header>
				<Modal.Content>
					<Header><Icon name='plug'/>{this.props.device.name}</Header>
					<p>Are you sure you want to permanently delete this device?</p>
				</Modal.Content>
				<Modal.Actions>
					<Button negative content={'No'} onClick={this.handleClose}/>
					<Button positive content={'Yes'} onClick={this.handleDelete}/>
				</Modal.Actions>
			</Modal>
		);
	}

}

class DeviceForm extends React.Component {

	state = {
		id: this.props.device.id || '',
		name: this.props.device.name || '',
		deviceTypeId: this.props.device.deviceType && this.props.device.deviceType.key,
		memoryAddress: this.props.device.address || '',
		readOnly: this.props.device.readOnly,
		dataTypeId: this.props.device.dataType && this.props.device.dataType.key,
		linked: this.props.device.linked,
		linkStationId: this.props.device.link && this.props.device.link.station && this.props.device.link.station.id,
		linkDeviceId: this.props.device.link && this.props.device.link.id,
		transformTypeId: this.props.device.transform && this.props.device.transform.transformType && this.props.device.transform.transformType.key,
		transformParameters: (this.props.device.transform && this.props.device.transform.parameters) || {},
		deviceTypes: [],
		dataTypes: [],
		linkStations: [],
		linkDevices: [],
		errorMessage: '',
	};

	componentDidMount() {
		this.loadDeviceTypes();
		this.loadDataTypes();
		if (this.props.device.link) {
			this.loadLinkStations();
			if (this.props.device.link.station) this.loadLinkDevices(this.props.device.link.station.id);
		}
	}

	loadDeviceTypes = () => {
		lookupService.getDeviceTypes((devicetypes) => (
			this.setState({deviceTypes: devicetypes})
		), (message) => {
			console.log(message);
		});
	};

	loadDataTypes = () => {
		lookupService.getDataTypes((datatypes) => (
			this.setState({dataTypes: datatypes})
		), (message) => {
			console.log(message);
		});
	};

	loadLinkStations = () => {
		tenantService.getStations(this.props.device.station.tenant.id, (stations) => {
			this.setState({
				linkStations: stations.filter((station) => {
					return station.id !== this.props.device.station.id;
				}).map((station) => {
					return {'key': station.id, 'value': station.id, 'text': station.name}
				})
			});
		}, (message) => {
			console.log(message);
		});
	};

	loadLinkDevices = (stationid) => {
		if (!stationid) return;
		stationService.getDevices(stationid, (devices) => {
			this.setState({
				linkDevices: devices.map((device) => {
					return {'key': device.id, 'value': device.id, 'text': '[' + this.props.device.tag + '] ' + device.name}
				})
			})
		}, (message) => {
			console.log(message);
		});
	};

	loadInitialTransformParameters = (transformTypeId) => {
		lookupService.getTransformParameters(transformTypeId, (parameters) => {
			this.setState({'transformParameters': parameters});
		}, (message) => {
			console.log(message);
		});
	};

	handleCreateTransformParameter = () => {
		let newId = Object.keys(this.state.transformParameters).length.toString();
		let parameters = {...this.state.transformParameters};
		parameters[newId] = {name: newId, value: ''};
		this.setState({transformParameters: parameters})
	};

	handleUpdateTransformParameter = (id, name, value) => {
		let parameters = {...this.state.transformParameters};
		parameters[id].name = name;
		parameters[id].value = value;
		this.setState({transformParameters: parameters})
	};

	handleDeleteTransformParameter = (id) => {
		let parameters = {...this.state.transformParameters};
		delete parameters[id];
		this.setState({transformParameters: parameters})
	};

	handleFieldChange = (event) => {
		this.setState({[event.target.name]: event.target.value})
	};

	handleCheckChange = (event, {name, checked}) => {
		this.setState({[name]: checked});
		if ('linked' === name && checked) {
			this.loadLinkStations();
		}
	};

	handleSelectChange = (event, {name, value}) => {
		let priorValue = this.state[name];

		this.setState({[name]: value});

		let saveTransformParameters = false;
		if (name === 'transformTypeId') {
			if (priorValue === 'INDEXED_EMAIL' && value === 'INDEXED_VALUE') saveTransformParameters = true;
			if (priorValue === 'INDEXED_VALUE' && value === 'INDEXED_EMAIL') saveTransformParameters = true;
		}

		if ('linkStationId' === name) {
			this.loadLinkDevices(value);
		} else if ('transformTypeId' === name) {
			if (!saveTransformParameters) this.loadInitialTransformParameters(value);
		}
	};

	checkForEnter = (event) => {
		if (event.key === 'Enter') this.handleSaveDevice();
	};

	handleSaveDevice = () => {
		this.props.onSaveDevice(
			{
				id: this.state.id,
				name: this.state.name,
				deviceType: this.state.deviceTypeId,
				address: this.state.memoryAddress,
				dataType: this.state.dataTypeId,
				readOnly: this.state.readOnly,
				linked: this.state.linked,
				link: {id: this.state.linkDeviceId},
				transform: {
					transformType: this.state.transformTypeId,
					parameters: Object.entries(this.state.transformParameters).map((parameter) => {
						return {'name': parameter[1].name, 'value': parameter[1].value}
					}),
				},
				station: {id: this.props.device.station.id},
			},
			() => {
				this.setState({errorMessage: 'Failed to save device configuration'})
			}
		)
	};

	handleCancelDevice = () => {
		this.props.onCancelDevice()
	};


	render() {
		return (
			<Form error={this.state.errorMessage !== ''}>
				<Grid>
					<Grid.Row>
						<Grid.Column verticalAlign='middle'>
							<Icon name={DEVICE_ICON} size='big'/>
						</Grid.Column>
						<Grid.Column verticalAlign='middle' width={12}>
							<Header>{this.state.name}</Header>
						</Grid.Column>
						<Grid.Column verticalAlign='middle'>
							<Header>[{this.state.id}]</Header>
						</Grid.Column>
						<Grid.Column floated='right' textAlign='right' verticalAlign='middle'>
							<Icon name={SAVE_ICON} link size='big' onClick={this.handleSaveDevice} color='green'/>
						</Grid.Column>
						<Grid.Column floated='right' textAlign='right' verticalAlign='middle'>
							<Icon name={CANCEL_ICON} link size='large' onClick={this.handleCancelDevice}/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Message error header={this.state.errorMessage}/>
							<Form.Group>
								<Form.Field name='name' width={8} control={Form.Input} required fluid label='Device Name' placeholder='Name' value={this.state.name} onChange={this.handleFieldChange} onKeyDown={this.checkForEnter}/>
								<Form.Field name='deviceTypeId' width={4} control={Form.Select} required fluid label='Device Type' placeholder='Type' value={this.state.deviceTypeId} options={this.state.deviceTypes} onChange={this.handleSelectChange}/>
								<Form.Field name='memoryAddress' width={4} control={Form.Input} required fluid label='Address' placeholder='0xA3 or 163' value={this.state.memoryAddress} onChange={this.handleFieldChange}/>
							</Form.Group>
							<Form.Group>
								<Form.Field name='dataTypeId' width={8} control={Form.Select} required fluid label='Data Type' placeholder='Data Type' value={this.state.dataTypeId} options={this.state.dataTypes} onChange={this.handleSelectChange}/>
								<Form.Field name='readOnly' width={4} control={Form.Checkbox} label='Read Only' inline checked={this.state.readOnly} onChange={this.handleCheckChange}/>
								<Form.Field name='linked' width={4} control={Form.Checkbox} label='Linked' inline checked={this.state.linked} onChange={this.handleCheckChange}/>
							</Form.Group>
							{this.state.linked &&
							<Form.Group>
								<Form.Field name='linkStationId' width={8} control={Form.Select} required fluid label='Link Station' placeholder='Station' value={this.state.linkStationId} options={this.state.linkStations}
														onChange={this.handleSelectChange}/>
								<Form.Field name='linkDeviceId' width={8} control={Form.Select} required fluid label='Link Device' placeholder='Device' value={this.state.linkDeviceId} options={this.state.linkDevices} onChange={this.handleSelectChange}/>
							</Form.Group>
							}
							{!this.state.linked &&
							<Transform transformTypeId={this.state.transformTypeId} transformParameters={this.state.transformParameters}
												 onTransformTypeChange={this.handleSelectChange} onCreateTransformParameter={this.handleCreateTransformParameter}
												 onUpdateTransformParameter={this.handleUpdateTransformParameter}
												 onDeleteTransformParameter={this.handleDeleteTransformParameter}/>
							}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
		)
	}
}

class Transform extends React.Component {

	state = {
		transformtypes: []
	};

	componentDidMount() {
		this.loadTransformTypes();
	}

	loadTransformTypes = () => {
		lookupService.getTransformTypes((transformtypes) => (
			this.setState({transformtypes: transformtypes})
		), (message) => {
			console.log(message);
		});
	};

	handleCreateTransformParameter = () => {
		this.props.onCreateTransformParameter();
	};

	handleUpdateTransformParameter = (id, name, value) => {
		this.props.onUpdateTransformParameter(id, name, value);
	};

	handleDeleteTransformParameter = (id) => {
		this.props.onDeleteTransformParameter(id);
	};

	render() {
		// This is a bit of a workaround, looking up the transform type from the selection list
		let transformtype = this.state.transformtypes.filter(t => t.key === this.props.transformTypeId)[0];
		// Must do a string compare because the select option only allows strings
		let customParameters = transformtype && transformtype.custom === 'true';
		return (
			<div>
				<Form.Group>
					<Form.Field name='transformTypeId' width={16} control={Form.Select} required fluid label='Transform' placeholder='Transform'
											value={this.props.transformTypeId} options={this.state.transformtypes} onChange={this.props.onTransformTypeChange}/>
				</Form.Group>
				<TransformParameterMap parameters={this.props.transformParameters} customParameters={customParameters}
															 onCreateTransformParameter={this.handleCreateTransformParameter} onUpdateTransformParameter={this.handleUpdateTransformParameter}
															 onDeleteTransformParameter={this.handleDeleteTransformParameter}/>
			</div>
		)
	}

}

class TransformParameterMap extends React.Component {

	handleUpdateParameter = (index, name, value) => {
		this.props.onUpdateTransformParameter(index, name, value);
	};

	handleDeleteParameter = (index) => {
		this.props.onDeleteTransformParameter(index);
	};

	render() {
		return (
			<Grid columns={3}>
				<Grid.Column width={16}>
					<Grid.Row>
						{
							Object.keys(this.props.parameters).map((id) =>
								<TransformParameter key={id} index={id} parameter={this.props.parameters[id]} customParameters={this.props.customParameters}
																		onUpdateParameter={this.handleUpdateParameter} onDeleteParameter={this.handleDeleteParameter}/>
							)
						}
					</Grid.Row>
					{this.props.customParameters &&
					<Grid.Row>
						<Button icon='add' fluid onClick={this.props.onCreateTransformParameter}/>
					</Grid.Row>
					}
				</Grid.Column>
			</Grid>
		)
	}

}

class TransformParameter extends React.Component {

	handleFieldChange = (event) => {
		if (event.target.name === 'parameterName') {
			this.props.onUpdateParameter(this.props.index, event.target.value, this.props.parameter.value);
		} else if (event.target.name === 'parameterValue') {
			this.props.onUpdateParameter(this.props.index, this.props.parameter.name, event.target.value);
		}
	};

	handleDeleteParameter = () => {
		this.props.onDeleteParameter(this.props.index);
	};

	render() {
		let value = (this.props.parameter && this.props.parameter.value) || '';
		if (this.props.customParameters) {
			return (
				<Form.Group>
					<Form.Field name='parameterName' fluid control={Form.Input} width={4} value={this.props.parameter.name} onChange={this.handleFieldChange}/>
					<Form.Field name='parameterValue' fluid control={Form.Input} width={11} value={value} onChange={this.handleFieldChange}/>
					<DeleteTransformParameterDialog parameter={this.props.parameter} onDeleteParameter={this.handleDeleteParameter}/>
				</Form.Group>
			)
		} else {
			return (
				<Form.Group>
					<Header className='four wide field'>{this.props.parameter.name}</Header>
					<Form.Field name='parameterValue' fluid control={Form.Input} width={12} value={value} onChange={this.handleFieldChange}/>
				</Form.Group>
			)
		}
	}

}

class DeleteTransformParameterDialog extends React.Component {

	state = {open: false};

	handleOpen = () => this.setState({open: true});

	handleClose = () => this.setState({open: false});

	handleDelete = () => {
		this.handleClose();
		this.props.onDeleteParameter();
	};

	render() {
		return (
			<Modal size={'mini'} centered={false} open={this.state.open} onClose={this.handleClose}
						 trigger={<Icon name={'trash'} size={'large'} link onClick={this.handleOpen}/>}>
				<Modal.Header>Delete Transform Parameter</Modal.Header>
				<Modal.Content>
					<Header><Icon name={DEVICE_ICON}/>{this.props.parameter.name}: {this.props.parameter.value}</Header>
					<p>Are you sure you want to permanently delete this transform parameter?</p>
				</Modal.Content>
				<Modal.Actions>
					<Button negative content={'No'} onClick={this.handleClose}/>
					<Button positive content={'Yes'} onClick={this.handleDelete}/>
				</Modal.Actions>
			</Modal>
		);
	}

}

