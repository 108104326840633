import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {Button, Container, Form, Icon, Input, List, Message, Segment} from 'semantic-ui-react'
import {userService} from './Api'

export default class Password extends Component {

	state = {
		id: '',
		oldPassword: '',
		newPassword: '',
		newPasswordVerify: '',
		newPasswordError: false,
		message: '',
		save: 'Save',
		redirectToReferrer: false
	};

	componentDidMount() {
		this.setState({redirectToReferrer: false});
		this.loadUserProfile();
	}

	loadUserProfile = () => {
		userService.getUserProfile((profile) => (
			this.setState({
				'id': profile.id,
			})
		), (failureMessage) => (
			console.log(failureMessage)
		));
	};

	handleOldPasswordChange = (event) => {
		this.setState({oldPassword: event.target.value});
	};

	handleNewPasswordChange = (event) => {
		let newPasswordError = event.target.value !== this.state.newPasswordVerify;
		this.setState({newPassword: event.target.value, newPasswordError: newPasswordError});
	};

	handleNewPasswordVerify = (event) => {
		let newPasswordError = event.target.value !== this.state.newPassword;
		this.setState({newPasswordVerify: event.target.value, newPasswordError: newPasswordError});
	};

	handleSave = () => {
		this.setState({save: 'Saving...'});

		const password = {
			id: this.state.id,
			password: this.state.oldPassword,
			newPassword: this.state.newPassword
		};

		userService.savePassword(password, () => {
			this.setState({redirectToReferrer: true});
		}, () => {
			this.setState({save: 'Save', message: 'Failed to save new password'});
		});
	};

	handleCancel = () => {
		this.setState({redirectToReferrer: true})
	};

	render() {
		const from = this.props.from;
		if (this.state.redirectToReferrer) return <Redirect to={from}/>;

		const message = this.state.message;

		return (
			<Container>
				<Form error={message !== ''}>
					<Message error header={message}/>
					<Form.Field id='password-current' control={Input} required fluid placeholder='Current Password' label='Current Password' type='password' onChange={this.handleOldPasswordChange}/>
					<Segment color='orange' inverted>
						<List>
							<List.Item>
								<Icon name='pin'/>
								Passwords are required to be eight characters or longer
							</List.Item>
						</List>
					</Segment>
					<Form.Field id='password-new' control={Input} required fluid placeholder='Enter New Password' label='Enter New Password' type='password' value={this.state.newPassword} error={this.state.newPasswordError}
											onChange={this.handleNewPasswordChange}/>
					<Form.Field id='password-verify' control={Input} required fluid placeholder='Verify New Password' label='Verify New Password' type='password' value={this.state.newPasswordVerify} error={this.state.newPasswordError}
											onChange={this.handleNewPasswordVerify}/>
					<Container textAlign='center'>
						<Button.Group>
							<Button positive onClick={this.handleSave}>{this.state.save}</Button>
							<Button negative onClick={this.handleCancel}>Cancel</Button>
						</Button.Group>
					</Container>
				</Form>
			</Container>
		);
	}

}