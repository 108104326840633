import React from "react";
import {Form, Grid, Header, Icon} from "semantic-ui-react";
import {CANCEL_ICON, SAVE_ICON, SERIES_ICON} from "../../config";
import {stationService} from "../../Api";

const LINE_TYPES = [
	{ key:'line', value:'line', text: 'Line'},
	{ key:'step', value:'step', text: 'Step'},
	{ key:'curve', value:'curve', text: 'Curve'},
	{ key:'dot', value:'dot', text: 'Dot'},
];

export class GraphSeriesEditor extends React.Component {

	state = {
		devices: [],
		stationLookup: [],
		deviceLookup: [],

		id: this.props.series.id || '',
		name: this.props.series.name || '',
		stationId: (this.props.series.station && this.props.series.station.id) || '',
		deviceId: (this.props.series.device && this.props.series.device.id) || '',
		graphId: (this.props.series.graph && this.props.series.graph.id) || '',
		scale: this.props.series.scale === undefined ? '' : this.props.series.scale,
		offset: this.props.series.offset === undefined ? '' : this.props.series.offset,
		linetype: this.props.series.linetype || '',
		linecolor: this.props.series.linecolor || '',
		linewidth: this.props.series.linewidth || '',
	};

	componentDidMount() {
		this.setState({stationLookup: this.props.stationLookup });
		if (this.props.series.station) this.loadDevices(this.props.series.station.id);
	}

	loadDevices = (stationId) => {
		if (stationId === undefined) return;

		stationService.getDevices(stationId, (devices) => {
			this.setState({
				devices: devices,
				deviceLookup: devices.map((device) => {
					const name = device.tag ? '[' + device.tag + '] ' + device.name : device.name;
					return {'key': device.id, 'value': device.id, 'text': name}
				})
			})
		}, (message) => {
			console.log(message);
		});
	};

	handleFieldChange = (event, {name, value}) => {
		// This method expects the component name to match the state key name
		switch (name) {
			case 'stationId' : {
				this.loadDevices(value);
				break;
			}
			default : {
				break;
			}
		}

		this.setState({[name]: value});
	};

	handleSaveSeries = () => {
		this.props.onSaveSeries({
			id: this.state.id,
			name: this.state.name,
			scale: this.state.scale,
			offset: this.state.offset,
			linetype: this.state.linetype,
			linecolor: this.state.linecolor,
			linewidth: this.state.linewidth,
			graph: {id: this.state.graphId},
			station: {id: this.state.stationId},
			device: {id: this.state.deviceId},
		});
	};

	handleCancelSeries = () => {
		this.props.onCancelSeries();
	};

	render() {
		return (
			<Grid>
				<Grid.Row color='grey'>
					<Grid.Column verticalAlign='middle'>
						<Icon name={SERIES_ICON} size='big' color='black'/>
					</Grid.Column>
					<Grid.Column verticalAlign='middle' width={13}>
						<Header>{this.state.name}</Header>
					</Grid.Column>
					<Grid.Column floated='right' textAlign='right' verticalAlign='middle'>
						<Icon name={SAVE_ICON} link size='big' color='green' onClick={this.handleSaveSeries}/>
					</Grid.Column>
					<Grid.Column floated='right' textAlign='right' verticalAlign='middle'>
						<Icon name={CANCEL_ICON} link size='large' color='black' onClick={this.handleCancelSeries}/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Form.Group>
							<Form.Field name='name' width={4} control={Form.Input} required fluid label='Series Name' value={this.state.name} onChange={this.handleFieldChange}/>
							<Form.Field name='stationId' width={4} control={Form.Select} required fluid label='Station' placeholder='Station' value={this.state.stationId}
													options={this.state.stationLookup} onChange={this.handleFieldChange}/>
							<Form.Field name='deviceId' width={4} control={Form.Select} required fluid label='Device' placeholder='Device' value={this.state.deviceId}
													options={this.state.deviceLookup} onChange={this.handleFieldChange}/>
							<Form.Field name='scale' width={2} control={Form.Input} required fluid label='Scale' value={this.state.scale} onChange={this.handleFieldChange}/>
							<Form.Field name='offset' width={2} control={Form.Input} required fluid label='Offset' value={this.state.offset} onChange={this.handleFieldChange}/>
						</Form.Group>
						<Form.Group>
							<Form.Field name='linetype' width={4} control={Form.Select} fluid label='Line Type' placeholder='Line Type' value={this.state.linetype} options={LINE_TYPES} onChange={this.handleFieldChange}/>
							<Form.Field name='linecolor' width={4} control={Form.Input} fluid label='Line Color' placeholder='#000000' value={this.state.linecolor} onChange={this.handleFieldChange}/>
							<Form.Field name='linewidth' width={4} control={Form.Input} fluid label='Line Width' placeholder='0.0' value={this.state.linewidth} onChange={this.handleFieldChange}/>
						</Form.Group>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		)
	}

}
