import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {authService, lookupService} from './Api'
import {Button, Form, Grid, Input, Message, Segment} from 'semantic-ui-react'
import logo from './logo-white.svg'

export class Login extends Component {

	state = {
		username: '',
		password: '',
		message: '',
		program: {name: '', version: '', timestamp: ''},
		redirectToReferrer: false
	};

	componentDidMount() {
		this.loadProgramInformation();
	}

	loadProgramInformation = () => {
		lookupService.getProgramInformation((program) => {
			this.setState({program: program})
		}, (message) => {
			console.log(message);
		});
	};

	login = () => {
		// This line helps the user know there is something happening
		this.setState({message: ''});
		authService.login(this.state.username, this.state.password, () => {
			this.setState({message: '', redirectToReferrer: true});
		}, (message) => {
			this.setState({message: 'Login failure'})
		});
	};

	handleUsernameChange = (event) => {
		this.setState({username: event.target.value});
	};

	handlePasswordChange = (event) => {
		this.setState({password: event.target.value});
	};

	render() {
		const message = this.state.message;
		const {from} = {from: {pathname: '/home'}};

		if (this.state.redirectToReferrer) return <Redirect to={from}/>;

		return (
			<div id='login'>
				<style>{`body > div, body > div > div, body > div > div > div { height: 100%; }`}</style>
				<Grid centered verticalAlign='middle' style={{height: '80%'}}>
					<Grid.Row columns={1}>
						<Grid.Column style={{maxWidth: 450}}>
							<Segment inverted color='blue'>
								<div className="login-header">
									<img className="login-logo" src={logo} alt="logo"/>
									<span className="login-title">Perform</span>
								</div>

								<Form error={message !== ''}>
									<Form.Field control={Input} id="username" autoComplete="username" fluid icon='user' iconPosition='left' placeholder='Username or Email Address' onChange={this.handleUsernameChange}/>
									<Form.Field control={Input} id="password" autoComplete="current-password" fluid icon='lock' iconPosition='left' placeholder='Password' type='password' onChange={this.handlePasswordChange}/>
									<Message error header={message}/>
									<Button fluid size='large' onClick={this.login}> Login </Button>
								</Form>
							</Segment>
							<Grid>
								<Grid.Row columns={2}>
									<Grid.Column>
										{this.state.program.version}
									</Grid.Column>
									<Grid.Column textAlign={'right'}>
										{this.state.program.timestamp}
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		);

	}

}
