import React, {Component} from 'react';
import {Button, Checkbox, Container, Form, Grid, Header, Icon, Message, Modal, Segment} from 'semantic-ui-react'
import {lookupService, stationService} from "../Api";
import DeviceManagement from './DeviceManagement';
import ProgressBar from '../ProgressBar'
import {CANCEL_ICON, DELETE_ICON, SAVE_ICON, STATION_ICON, UPLOAD_ICON} from "../config";

export default class StationManagementView extends Component {

	state = {
		redirectToReferrer: false,
		stations: [],
		tenants: [],
		timezones: [],
	};

	componentDidMount() {
		this.loadStations();
		this.loadTenants();
		this.loadTimezones();
		this.refreshTimer = setInterval(this.loadStations, 5000);
	}

	componentWillUnmount() {
		clearInterval(this.refreshTimer);
	}

	loadStations = () => {
		stationService.getStations((stations) => (
			this.setState({stations: stations})
		), (failureMessage) => {
			console.log(failureMessage)
		});
	};

	loadTenants = () => {
		lookupService.getTenants((tenants) => (
			this.setState({tenants: tenants})
		), (message) => {
			console.log(message);
		});
	};

	loadTimezones = () => {
		lookupService.getTimezones((timezones) => (
			this.setState({timezones: timezones})
		), (message) => {
			console.log(message);
		});
	};

	handleCreateStation = (station, success, failure) => {
		stationService.newStation(station, () => {
			success();
			this.loadStations()
		}, () => {
			failure();
		});
	};

	handleUpdateStation = (station, success, failure) => {
		stationService.saveStation(station, () => {
			success();
			this.loadStations()
		}, () => {
			failure();
		});
		this.setState({editing: false});
	};

	handleDeleteStation = (id) => {
		stationService.deleteStationById(id, () => {
			this.loadStations()
		}, () => {
			this.loadStations()
		});
	};

	render() {
		return (
			<StationManagement
				stations={this.state.stations}
				tenants={this.state.tenants}
				timezones={this.state.timezones}
				onCreateStation={this.handleCreateStation}
				onUpdateStation={this.handleUpdateStation}
				onDeleteStation={this.handleDeleteStation}
			/>
		)
	}

}

class StationManagement extends Component {

	state = {
		editing: false,
		station: {}
	};

	handleFormOpen = () => this.setState({station: {enabled: true, tenant: {}, devices: []}, editing: true});

	handleFormClose = () => this.setState({editing: false});

	handleEditStation = (station) => {
		this.setState({station: station, editing: true});
	};

	handleSaveStation = (station, failure) => {
		const success = () => {
			this.handleFormClose();
		};

		if (station.id === '') {
			this.handleCreateStation(station, success, failure)
		} else {
			this.handleUpdateStation(station, success, failure)
		}
	};

	handleEnableStation = (station, failure) => {
		const success = () => {
		};

		if (station.id === '') {
			this.handleCreateStation(station, success, failure)
		} else {
			this.handleUpdateStation(station, success, failure)
		}
	};

	handleCreateStation = (station, success, failure) => {
		this.props.onCreateStation(station, success, failure);
	};

	handleUpdateStation = (station, success, failure) => {
		this.props.onUpdateStation(station, success, failure);
	};

	handleDeleteStation = (id) => {
		this.props.onDeleteStation(id)
	};

	handleCancelStation = () => {
		this.handleFormClose();
	};

	render() {
		if (this.state.editing) {
			return (
				<Container>
					<StationForm station={this.state.station} tenants={this.props.tenants} timezones={this.props.timezones} onSaveStation={this.handleSaveStation} onEnableStation={this.handleEnableStation} onCancelStation={this.handleCancelStation}/>
				</Container>
			);
		} else {
			return (
				<Container>
					<StationList
						stations={this.props.stations}
						tenants={this.props.tenants}
						timezones={this.props.timezones}
						onNewStation={this.handleFormOpen}
						onEditStation={this.handleEditStation}
						onDeleteStation={this.handleDeleteStation}
					/>
				</Container>
			);
		}
	}
}

class StationList extends Component {

	handleNewStationOnTop = () => {
		this.props.onNewStation()
	};

	handleNewStationOnBottom = () => {
		this.props.onNewStation()
	};

	handleEditStation = (station) => {
		this.props.onEditStation(station)
	};

	handleDeleteStation = (id) => {
		this.props.onDeleteStation(id)
	};

	render() {
		return (
			<Container>
				<Button icon='add' fluid onClick={this.handleNewStationOnTop}/>

				<Segment.Group>
					{
						this.props.stations.map((station) => (
								// Probably because the key is the same, station property changes are not propagated
								// Adding the name to the key fixed the issue, but what about other properties?
								// Do I need to create a state hash to be put in the key?
								// Using the map index also does not work because it does not change.
								<Station key={station.id} station={station} onEditStation={this.handleEditStation} onDeleteStation={this.handleDeleteStation}/>
							)
						)
					}
				</Segment.Group>
				<Segment>
					<Button icon='add' fluid onClick={this.handleNewStationOnBottom}/>
				</Segment>
			</Container>
		);
	}

}

class Station extends Component {

	handleEditStation = () => this.props.onEditStation(this.props.station);

	handleDeleteStation = () => this.props.onDeleteStation(this.props.station.id);

	render() {
		let statusColor = 'red';
		let enabled = this.props.station.enabled;
		let connected = this.props.station.connected;
		if (!enabled && !connected) statusColor = 'grey';
		if (!enabled && connected) statusColor = 'yellow';
		if (enabled && !connected) statusColor = 'pink';
		if (enabled && connected) statusColor = 'green';
		return (
			<Segment>
				<Grid columns={4}>
					<Grid.Row>
						<Grid.Column width={6}>
							<Header style={{cursor: 'pointer'}} onClick={this.handleEditStation}><Icon name={STATION_ICON} color={statusColor}/>{this.props.station.name}</Header>
						</Grid.Column>
						<Grid.Column width={6}>
							{this.props.station.tenant.name}
						</Grid.Column>
						<Grid.Column textAlign='center' width={3}>
							{this.props.station.host}
						</Grid.Column>
						<Grid.Column textAlign='right' width={1}>
							<DeleteStationDialog station={this.props.station} onDeleteStation={this.handleDeleteStation}/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		)
	}

}

class StationForm extends Component {

	state = {
		id: (this.props.station.id !== undefined) ? this.props.station.id : '',
		name: this.props.station.name || '',
		description: this.props.station.description || '',
		host: this.props.station.host || '',
		poll: (this.props.station.poll !== undefined) ? this.props.station.poll : 60,
		tenant: this.props.station.tenant.id || '',
		timezone: this.props.station.timezone || '',
		enabled: this.props.station.enabled,
		accessKeyA: this.props.station.accessKeyA || 'empty',
		accessKeyB: this.props.station.accessKeyB || 'empty',
		errorMessage: '',
	};

	handleFieldChange = (event) => {
		this.setState({[event.target.name]: event.target.value})
	};

	handleCheckChange = (event, {name, checked}) => {
		this.setState({[name]: checked});
		if (name === 'enabled') this.handleEnableStation(checked);
	};

	handleSelectChange = (event, {name, value}) => {
		this.setState({[name]: value});
	};

	checkForEnter = (event) => {
		if (event.key === 'Enter') this.handleSaveStation();
	};

	handleSaveStation = () => {
		this.props.onSaveStation(
			this.getNewState(this.state.enabled),
			() => {
				this.setState({errorMessage: 'Failed to save station configuration'})
			}
		)
	};

	handleEnableStation = (enabled) => {
		this.props.onEnableStation(
			this.getNewState(enabled),
			() => {
				this.setState({errorMessage: 'Failed to save station configuration'})
			}
		)
	};

	handleCancelStation = () => {
		this.props.onCancelStation()
	};

	handleDeviceImport = () => {
		this.forceUpdate();
	};

	handleRotateAccessKey = (key) => {
		stationService.rotateAccessKey(
			this.state.id,
			key,
			(response) => {
				if (key === 'a') this.setState({accessKeyA: response.accessKey})
				if (key === 'b') this.setState({accessKeyB: response.accessKey})
			},
			() => {
				this.setState({errorMessage: 'Failed to rotate access key'})
			}
		)
	};

	getNewState = (enabled) => {
		return {
			id: this.state.id,
			name: this.state.name,
			description: this.state.description,
			host: this.state.host,
			poll: this.state.poll,
			tenant: {id: this.state.tenant},
			timezone: this.state.timezone,
			enabled: enabled,
			devices: [],
		}
	};

	render() {
		return (
			<Segment>
				<Grid>
					<Grid.Row>
						<Grid.Column verticalAlign='middle'>
							<Icon name={STATION_ICON} size='big'/>
						</Grid.Column>
						<Grid.Column verticalAlign='middle' width={10}>
							<Header>{this.state.name}</Header>
						</Grid.Column>
						<Grid.Column verticalAlign='middle' width={1}>
							<Header>[{this.state.id}]</Header>
						</Grid.Column>
						<Grid.Column width={2}>
							<Checkbox name='enabled' toggle checked={this.state.enabled} onClick={this.handleCheckChange}/>
						</Grid.Column>
						<Grid.Column floated='right' textAlign='right' verticalAlign='middle'>
							<Icon name={SAVE_ICON} link size='big' onClick={this.handleSaveStation} color='green'/>
						</Grid.Column>
						<Grid.Column floated='right' textAlign='right' verticalAlign='middle'>
							<Icon name={CANCEL_ICON} link size='large' onClick={this.handleCancelStation}/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Form error={this.state.errorMessage !== ''}>
								<Message error header={this.state.errorMessage}/>
								<Form.Group>
									<Form.Field name='name' width={4} control={Form.Input} required fluid label='Station Name' placeholder='Name' value={this.state.name} onChange={this.handleFieldChange} onKeyDown={this.checkForEnter}/>
									<Form.Field name='description' width={12} control={Form.Input} fluid label='Description' placeholder='' value={this.state.description} onChange={this.handleFieldChange} onKeyDown={this.checkForEnter}/>
								</Form.Group>
								<Form.Group widths='equal'>
									<Form.Field name='host' control={Form.Input} required fluid label='Host or IP Address' placeholder='0.0.0.0' value={this.state.host} onChange={this.handleFieldChange} onKeyDown={this.checkForEnter}/>
									<Form.Field name='poll' control={Form.Input} required fluid label='Sample Period (seconds)' placeholder='60' value={this.state.poll} onChange={this.handleFieldChange} onKeyDown={this.checkForEnter}/>
									<Form.Field name='tenant' control={Form.Select} search required fluid label='Client' placeholder='Tenant' value={this.state.tenant} options={this.props.tenants} onChange={this.handleSelectChange}/>
									<Form.Field name='timezone' control={Form.Select} search required fluid label='Time Zone' placeholder='Timezone' value={this.state.timezone} options={this.props.timezones} onChange={this.handleSelectChange}/>
								</Form.Group>
							</Form>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width='8'>
							<Button icon='refresh' onClick={() => this.handleRotateAccessKey('a')}/>
							<b>Access Key A:</b> {this.state.accessKeyA}
						</Grid.Column>
						<Grid.Column width='8'>
							<Button icon='refresh' onClick={() => this.handleRotateAccessKey('b')}/>
							<b>Access Key B:</b> {this.state.accessKeyB}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							{this.state.id !== '' && <DeviceImport stationId={this.state.id} onDeviceImport={this.handleDeviceImport}/>}
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<DeviceManagement station={this.props.station}/>
			</Segment>
		)

	}

}

class DeleteStationDialog extends Component {

	state = {open: false};

	handleOpen = () => this.setState({open: true});

	handleClose = () => this.setState({open: false});

	handleDelete = () => {
		this.handleClose();
		this.props.onDeleteStation();
	};

	render() {
		return (
			<Modal size='mini' centered={false} open={this.state.open} onClose={this.handleClose} trigger={<Icon name={DELETE_ICON} size='large' link onClick={this.handleOpen}/>}>
				<Modal.Header>Delete Station</Modal.Header>
				<Modal.Content>
					<Header><Icon name={STATION_ICON}/>{this.props.station.name}</Header>
					<p>Are you sure you want to permanently delete this station?</p>
				</Modal.Content>
				<Modal.Actions>
					<Button negative content='No' onClick={this.handleClose}/>
					<Button positive content='Yes' onClick={this.handleDelete}/>
				</Modal.Actions>
			</Modal>
		);
	}

}

class DeviceImport extends Component {

	state = {
		progress: 0,
		status: '',
		message: ''
	};

	constructor(props) {
		super(props);
		this.fileInputRef = React.createRef();
	}

	openFileDialog = () => {
		if (this.props.disabled) return;
		this.fileInputRef.current.click();
	};

	onFilesAdded = (event) => {
		if (this.props.disabled) return;
		const files = event.target.files;

		this.setState({progress: 0});

		stationService.importDevices(this.props.stationId, files.item(0), (event) => {
			// progress
			const percent = (event.loaded / event.total) * 100;
			this.setState({progress: percent});
		}, (event) => {
			// success
			this.setState({progress: 100});
			setTimeout(this.props.onDeviceImport, 100);
		}, (event) => {
			// failure
			console.log("error="+ JSON.stringify(event))
			let error = JSON.parse(event)
			this.setState({progress: 0, status: 'error', message: error.message});
		});
	};

	handleFieldChange = (event) => {
		this.setState({[event.target.name]: event.target.value})
	};

	static fileListToArray(list) {
		const array = [];
		for (let i = 0; i < list.length; i++) {
			array.push(list.item(i));
		}
		return array;
	}

	render() {
		return (
			<Grid>
				<Grid.Row verticalAlign='middle'>
					<Grid.Column width={6}>
						<input ref={this.fileInputRef} className='embeddedfileupload' type='file' onChange={this.onFilesAdded}/>
						<Button fluid icon={UPLOAD_ICON} content='Import Devices' onClick={this.openFileDialog}/>
					</Grid.Column>
					<Grid.Column width={10}>
						<ProgressBar progress={this.state.progress} status={this.state.status} message={this.state.message}/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		)
	}

}

