import React from "react";
import {Redirect, Route} from "react-router-dom";
import {authService} from "./Api";

export const PrivateRoute = ({component: Component, ...rest}) => (

	<Route
		{...rest}
		render={(props) => (
			authService.loggedIn() ? (
				<Component {...props} />
			) : (
				<Redirect to={{pathname: "/login", state: {from: props.location}}}/>
			)
		)}
	/>

);
