import React, {Component} from 'react';
import {Button, Container, Form, Grid, Header, Icon, Input, Message, Modal, Segment, Select} from 'semantic-ui-react'
import {lookupService, userService} from '../Api'
import {EMAIL_PATTERN, PHONE_PATTERN} from "../config";
import {CANCEL_ICON, DELETE_ICON, SAVE_ICON, USER_ICON} from "../config";

export default class UserManagementView extends Component {

	state = {
		redirectToReferrer: false,
		users: [],
		roles: [],
		tenants: [],
		timezones: [],
	};

	componentDidMount() {
		this.loadUsers();
		this.loadRoles();
		this.loadTenants();
		this.loadTimezones();
		this.refreshTimer = setInterval(this.loadUsers, 2000);
	}

	componentWillUnmount() {
		clearInterval(this.refreshTimer);
	}

	loadUsers = () => {
		userService.getUsers((users) => {
			this.setState({users: users});
			console.log( JSON.stringify(users ));
		}, (message) => {
			console.log(message)
		});
	};

	loadTenants = () => {
		lookupService.getTenants((tenants) => (
			this.setState({tenants: tenants})
		), (message) => {
			console.log(message);
		});
	};

	loadRoles = () => {
		lookupService.getRoles((roles) => (
			this.setState({roles: roles})
		), (message) => {
			console.log(message);
		});
	};

	loadTimezones = () => {
		lookupService.getTimezones((zones) => (
			this.setState({timezones: zones})
		), (message) => {
			console.log(message);
		});
	};

	handleCreateUser = (user, success, failure) => {
		userService.newUser(user, () => {
			success();
			this.loadUsers()
		}, () => {
			failure()
		});
	};

	handleUpdateUser = (user, success, failure) => {
		userService.saveUser(user, () => {
			success();
			this.loadUsers()
		}, () => {
			failure()
		});
		this.setState({editing: false});
	};

	handleDeleteUser = (id) => {
		userService.deleteUserById(id, () => {
			this.loadUsers()
		}, () => {
			this.loadUsers()
		});
	};

	render() {

		return (
			<UserManagement
				users={this.state.users}
				roles={this.state.roles}
				tenants={this.state.tenants}
				timezones={this.state.timezones}
				onCreateUser={this.handleCreateUser}
				onUpdateUser={this.handleUpdateUser}
				onDeleteUser={this.handleDeleteUser}
			/>
		);

	}

}

class UserManagement extends Component {

	state = {
		editing: false,
		user: {id: '', name: '', tenant: {}, roles: ['USER']}
	};

	handleFormOpen = () => this.setState({user: {id: '', name: '', tenant: {}, roles: ['USER']}, editing: true});

	handleFormClose = () => this.setState({editing: false});

	handleEditUser = (user) => {
		this.setState({user: user, editing: true});
	};

	handleSaveUser = (user, failure) => {
		if (user.id === '') {
			this.handleCreateUser(user, failure)
		} else {
			this.handleUpdateUser(user, failure)
		}
	};

	handleCreateUser = (user, failure) => {
		this.props.onCreateUser(user, () => {
			this.handleFormClose();
		}, failure)
	};

	handleUpdateUser = (user, failure) => {
		this.props.onUpdateUser(user, () => {
			this.handleFormClose();
		}, failure)
	};

	handleDeleteUser = (id) => {
		this.props.onDeleteUser(id)
	};

	handleCancelUser = () => {
		this.handleFormClose();
	};

	render() {
		if (this.state.editing) {
			return (
				<Container>
					<UserForm user={this.state.user} roles={this.props.roles} tenants={this.props.tenants} timezones={this.props.timezones} onSaveUser={this.handleSaveUser} onCancelUser={this.handleCancelUser}/>
				</Container>
			)
		} else {
			return (
				<Container>
					<UserList
						users={this.props.users}
						roles={this.props.roles}
						tenants={this.props.tenants}
						timezones={this.props.timezones}
						onNewUser={this.handleFormOpen}
						onEditUser={this.handleEditUser}
						onDeleteUser={this.handleDeleteUser}
					/>
				</Container>
			)
		}
	}

}

class UserList extends Component {

	handleNewUser = () => {
		this.props.onNewUser()
	};

	handleEditUser = (user) => {
		this.props.onEditUser(user)
	};

	handleDeleteUser = (id) => {
		this.props.onDeleteUser(id)
	};

	render() {
		return (
			<Container>
				<Segment.Group>
					{
						this.props.users.map((user) => (
							// Probably because the key is the same, user property changes are not propagated
							// Adding the name to the key fixed the issue, but what about other properties?
							// Do I need to create a state hash to be put in the key?
							// Using the map index also does not work because it does not change.
							<User
								key={user.id}
								user={user}
								roles={this.props.roles}
								tenants={this.props.tenants}
								timezones={this.props.timezones}
								onEditUser={this.handleEditUser}
								onDeleteUser={this.handleDeleteUser}
							/>
						))
					}
				</Segment.Group>
				<Button icon='add' fluid onClick={this.handleNewUser}/>
			</Container>
		);
	}

}

// class EditableUser extends Component {
//
// 	state = {
// 		editing: false,
// 		user: {id: '', name: ''}
// 	};
//
// 	componentDidMount() {
// 		this.setState({user: this.props.user})
// 	}
//
// 	handleEditUser = () => {
// 		this.setState({editing: true});
// 	};
//
// 	handleUpdateUser = (user, failure) => {
// 		this.props.onUpdateUser(user, () => {
// 			this.setState({user: {...this.state.user, ...user}, editing: false});
// 		}, failure);
// 	};
//
// 	handleCancelUser = () => {
// 		this.setState({editing: false});
// 	};
//
// 	handleDeleteUser = () => {
// 		this.props.onDeleteUser(this.props.user.id)
// 	};
//
// 	render() {
// 		if (this.state.editing) {
// 			return (
// 				<UserForm
// 					user={this.state.user}
// 					roles={this.props.roles}
// 					tenants={this.props.tenants}
// 					timezones={this.props.timezones}
// 					onSaveUser={this.handleUpdateUser}
// 					onCancelUser={this.handleCancelUser}
// 				/>
// 			);
// 		} else {
// 			return (
// 				<User
// 					user={this.state.user}
// 					onEditUser={this.handleEditUser}
// 					onDeleteUser={this.handleDeleteUser}
// 				/>
// 			)
// 		}
// 	}
// }
//
// class ToggleableUserForm extends Component {
//
// 	state = {
// 		editing: false
// 	};
//
// 	handleFormOpen = () => this.setState({editing: true});
//
// 	handleFormClose = () => this.setState({editing: false});
//
// 	handleNewUser = (user, failure) => {
// 		this.props.onNewUser(user, () => {
// 			this.handleFormClose();
// 		}, failure);
// 	};
//
// 	handleCancelUser = () => this.handleFormClose();
//
// 	render() {
// 		if (this.state.editing) {
// 			return (
// 				<UserForm
// 					newUser
// 					user={{tenant: {}, roles: ['USER']}}
// 					roles={this.props.roles}
// 					tenants={this.props.tenants}
// 					timezones={this.props.timezones}
// 					onSaveUser={this.handleNewUser}
// 					onCancelUser={this.handleCancelUser}
// 				/>
// 			)
// 		} else {
// 			return (
// 				<Table.Row>
// 					<Table.Cell colSpan='100'>
// 						<Button icon='add' fluid onClick={this.handleFormOpen}/>
// 					</Table.Cell>
// 				</Table.Row>
// 			)
// 		}
// 	}
//
// }

class User extends Component {

	handleEditUser = () => this.props.onEditUser(this.props.user);

	handleDeleteUser = () => this.props.onDeleteUser(this.props.user.id);

	render() {
		return (
			<Segment>
				<Grid columns={4}>
					<Grid.Row>
						<Grid.Column width={5}>
							<Header style={{cursor: 'pointer'}} onClick={this.handleEditUser}><Icon name={USER_ICON}/>{this.props.user.name}</Header>
						</Grid.Column>
						<Grid.Column width={4}>
							{this.props.user.tenant.name}
						</Grid.Column>
						<Grid.Column width={4}>
							{this.props.user.emailAddress}
						</Grid.Column>
						<Grid.Column width={2}>
							{this.props.user.phone}
						</Grid.Column>
						<Grid.Column width={1}>
							<DeleteUserDialog user={this.props.user} onDeleteUser={this.handleDeleteUser}/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		)
	}

}

class UserForm extends Component {

	state = {
		id: this.props.user.id || '',
		tenant: this.props.user.tenant.id || '',
		username: this.props.user.username || '',
		role: this.props.user.roles[0] || 'USER',
		name: this.props.user.name || '',
		phone: this.props.user.phone || '',
		phoneError: false,
		emailAddress: this.props.user.emailAddress || '',
		emailError: false,
		alertAddress: this.props.user.alertAddress || '',
		alertError: false,
		newPassword: '',
		newPasswordVerify: '',
		timezone: this.props.user.timezone || '',
		tenants: this.props.tenants || [],
		roles: this.props.roles || [],
		timezones: this.props.timezones || [],
		errorMessage: '',
	};

	handleFieldChange = (event) => {
		this.setState({[event.target.name]: event.target.value});
		if (event.target.name === 'phone') this.setState({phoneError: PHONE_PATTERN.test(event.target.value)});
		if (event.target.name === 'emailAddress') this.setState({emailError: !(event.target.value === "" || EMAIL_PATTERN.test(event.target.value))});
		if (event.target.name === 'alertAddress') this.setState({alertError: !(event.target.value === "" || EMAIL_PATTERN.test(event.target.value))});
	};

	handleSelectChange = (event, {name, value}) => {
		this.setState({[name]: value});
	};

	handleKeyPress = (event) => {
		if (event.key === 'Enter') this.handleSaveUser();
	};

	handleSaveUser = () => {
		if (this.state.newPassword !== this.state.newPasswordVerify) {
			this.setState({errorMessage: 'Passwords do not match'});
			return;
		}
		this.props.onSaveUser(
			{
				id: this.state.id,
				name: this.state.name,
				tenant: {id: this.state.tenant},
				phone: this.state.phone,
				emailAddress: this.state.emailAddress,
				alertAddress: this.state.alertAddress,
				roles: [this.state.role],
				username: this.state.username,
				newPassword: this.state.newPassword,
				timezone: this.state.timezone,
			},
			() => {
				this.setState({errorMessage: 'Failed to save user information'})
			}
		)
	};

	handleCancelUser = () => {
		this.props.onCancelUser()
	};

	render() {
		return (
			<div>
				<Segment>
					<Grid>
						<Grid.Row>
							<Grid.Column verticalAlign='middle'>
								<Icon name={USER_ICON} size='big'/>
							</Grid.Column>
							<Grid.Column verticalAlign='middle' width={13}>
								<Header>{this.state.name}</Header>
							</Grid.Column>
							<Grid.Column floated='right' textAlign='right' verticalAlign='middle'>
								<Icon name={SAVE_ICON} link size='big' onClick={this.handleSaveUser} color='green'/>
							</Grid.Column>
							<Grid.Column floated='right' textAlign='right' verticalAlign='middle'>
								<Icon name={CANCEL_ICON} link size='large' onClick={this.handleCancelUser}/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								<Form error={this.state.errorMessage !== ''}>
									<Message error header={this.state.errorMessage}/>
									<Form.Group widths='equal'>
										<Form.Field name='name' control={Input} required fluid label='Name' placeholder='Name' value={this.state.name} onChange={this.handleFieldChange} onKeyDown={this.handleKeyPress}/>
										<Form.Field name='emailAddress' control={Input} required fluid label='Contact Email Address' placeholder='Contact Email Address' value={this.state.emailAddress} onChange={this.handleFieldChange} onKeyDown={this.handleKeyPress} error={this.state.emailError}/>
										<Form.Field name='alertAddress' control={Input} fluid label='Alert Email Address' placeholder='Alert Email Address' value={this.state.alertAddress} onChange={this.handleFieldChange} onKeyDown={this.handleKeyPress} error={this.state.alertError}/>
									</Form.Group>
									<Form.Group widths='equal'>
										<Form.Field name='tenant' width={3} control={Form.Select} search required fluid label='Client' placeholder='Tenant' value={this.state.tenant} options={this.props.tenants} onChange={this.handleSelectChange}/>
										<Form.Field name='role' width={4} control={Select} required fluid label='Role' placeholder='Role' value={this.state.role} options={this.state.roles} onChange={this.handleSelectChange}/>
										<Form.Field name='timezone' width={3} control={Form.Select} search required fluid label='Time Zone' placeholder='Timezone' value={this.state.timezone} options={this.props.timezones} onChange={this.handleSelectChange}/>
										<Form.Field name='phone' width={2} control={Input} fluid label='Phone' placeholder='Phone' value={this.state.phone} onChange={this.handleFieldChange} onKeyDown={this.handleKeyPress} error={this.state.phoneError}/>
									</Form.Group>
									<Form.Group widths='equal'>
									</Form.Group>
									<Form.Group widths='equal'>
										<Form.Field name='username' control={Input} required fluid label='Username' placeholder='Username' value={this.state.username} onChange={this.handleFieldChange} onKeyDown={this.handleKeyPress}/>
										<Form.Field name='newPassword' control={Input} required fluid label='Enter New Password' placeholder='Enter New Password' type='password' value={this.state.newPassword} error={this.state.newPasswordError} onChange={this.handleFieldChange}/>
										<Form.Field name='newPasswordVerify' control={Input} required fluid label='Verify New Password' placeholder='Verify New Password' type='password' value={this.state.newPasswordVerify} error={this.state.newPasswordError} onChange={this.handleFieldChange}/>
									</Form.Group>
								</Form>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Segment>
			</div>
		)
	}

}

class DeleteUserDialog extends Component {

	state = {open: false};

	handleOpen = () => this.setState({open: true});

	handleClose = () => this.setState({open: false});

	handleDelete = () => {
		this.handleClose();
		this.props.onDeleteUser();
	};

	render() {
		return (
			<Modal size='mini' centered={false} open={this.state.open} onClose={this.handleClose} trigger={<Icon name={DELETE_ICON} size='large' link onClick={this.handleOpen}/>}>
				<Modal.Header>Delete User</Modal.Header>
				<Modal.Content>
					<Header><Icon name={USER_ICON}/>{this.props.user.name}</Header>
					<p>Are you sure you want to permanently delete this user?</p>
				</Modal.Content>
				<Modal.Actions>
					<Button negative content='No' onClick={this.handleClose}/>
					<Button positive content='Yes' onClick={this.handleDelete}/>
				</Modal.Actions>
			</Modal>
		);
	}

}
