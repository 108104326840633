import {ApiService} from "./ApiService";

export class DeviceService extends ApiService {

	newDevice(device, success, failure) {
		this.fetch(`${this.uri}/api/devices`, {
			method: 'POST',
			body: JSON.stringify(device)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	saveDevice(device, success, failure) {
		console.log( JSON.stringify(device) );
		this.fetch(`${this.uri}/api/devices`, {
			method: 'PUT',
			body: JSON.stringify(device)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	deleteDeviceById(id, success, failure) {
		this.fetch(`${this.uri}/api/devices/${id}`, {
			method: 'DELETE'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

}
