import React from 'react';
import {Divider, Grid, Segment} from 'semantic-ui-react'

export default class Separator extends React.Component {

	render() {
		return (
			<Segment inverted color='teal'>
				<Grid>
					<Grid.Row>
						<Grid.Column width={16}>
							<Divider horizontal fitted>{this.props.metric.name}</Divider>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		)
	}

}

