import * as Util from './Util'
import React from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import {LastLocationProvider, useLastLocation} from 'react-router-last-location';
import {PrivateRoute} from "./PrivateRoute"
import {Login} from "./Login"
import {Grid, Header, Icon, Label, Menu, Segment, Sidebar} from 'semantic-ui-react'
import Board from './Board'
import Profile from './Profile'
import Password from './Password'
import * as Role from './Role'
import * as User from './User'
import BoardManagementView from './manage/BoardManagementView'
import TenantManagementView from './manage/TenantManagementView'
import UserManagementView from './manage/UserManagementView'
import StationManagementView from './manage/StationManagementView'
import BoardMenu from './menu/BoardMenu'
import TenantMenu from './menu/TenantMenu'
import SuperMenu from './menu/SuperMenu'
import UserMenu from './menu/UserMenu'
import {ANIMATION_DURATION, BOARDS_ICON, CONFIG_ICON, HOME_ICON, USER_ICON} from './config'
import {boardService, tenantService, userService} from "./Api";

export default class Home extends React.Component {

	render() {
		return (
			<Router>
				<LastLocationProvider>
					<div id='router'>
						<Switch>
							<Route path="/login" component={Login}/>
							<PrivateRoute path="/home" component={Program}/>
							<PrivateRoute path="/board" component={Program}/>
							<PrivateRoute path="/profile" component={Program}/>
							<PrivateRoute path="/password" component={Program}/>
							<PrivateRoute path="/manage/boards" component={Program}/>
							<PrivateRoute path="/manage/users" component={Program}/>
							<PrivateRoute path="/manage/tenants" component={Program}/>
							<PrivateRoute path="/manage/stations" component={Program}/>
							<Redirect to="/home"/>
						</Switch>
					</div>
				</LastLocationProvider>
			</Router>
		);
	}

}

class Program extends React.Component {

	state = {
		user: {id: '', name: '', roles: [], homeBoardId: 0},
		tenant: {id: '', name: ''},
		boards: [],
		menuVisible: '',
	};

	componentDidMount() {
		userService.getUserProfile((profile) => {
			this.setState({user: profile});
			this.loadTenant(profile.tenant.id);
			this.loadBoards(profile.tenant.id);
		}, (message) => {
			console.log(message);
		});
	}

	loadTenant = (tenantId) => {
		tenantService.getTenant(tenantId, (tenant) => {
			this.setState({tenant: tenant});
		}, (failureMessage) => {
			console.log(failureMessage)
		});
	};

	loadBoards = (tenantId) => {
		if (!tenantId) return;
		boardService.getBoards(tenantId, (boards) => {
			this.setState({boards: boards});
		}, (failureMessage) => {
			console.log(failureMessage)
		});
	};

	handleHomeClick = () => {
		setTimeout(this.props.history.push, ANIMATION_DURATION, '/home');
	};

	handleBoardMenuOpen = () => {
		this.setState({'menuVisible': 'board'});
	};

	handleTenantMenuOpen = () => {
		this.setState({'menuVisible': 'tenant'});
	};

	handleSuperMenuOpen = () => {
		this.setState({'menuVisible': 'super'});
	};

	handleUserMenuOpen = () => {
		this.setState({'menuVisible': 'user'});
	};

	handleCloseMenus = () => {
		this.setState({'menuVisible': ''});
	};

	handleChangeTenant = (tenantId) => {
		this.handleCloseMenus();
		this.loadTenant(tenantId);
		this.loadBoards(tenantId);
		setTimeout(this.props.history.push, ANIMATION_DURATION, '/home');
	};

	handleTenantUpdate = () => {
		this.loadBoards(this.state.tenant.id);
	};

	render() {
		const mode = window.location.pathname.substr(1);
		const tenant = this.state.tenant;
		let boardId = parseInt(Util.getUrlParameterByName('id'), 10);
		if (mode === 'home') boardId = this.state.user.homeBoardId;

		//console.log( "boards: " + JSON.stringify( this.state.boards ));
		//console.log("mode=" + mode + " boardid=" + boardId + " board=" + board);

		const board = this.state.boards.filter(b => b.id === boardId)[0];

		let title;
		switch (mode) {
			case 'profile' : {
				title = 'User Profile';
				break;
			}
			case 'password' : {
				title = 'Change Password';
				break;
			}
			case 'manage/boards' : {
				title = 'Board Management';
				break;
			}
			case 'manage/users' : {
				title = 'User Management';
				break;
			}
			case 'manage/tenants' : {
				title = 'Client Management';
				break;
			}
			case 'manage/stations' : {
				title = 'Station Management';
				break;
			}
			default : {
				title = board === undefined ? tenant.name : board.name;
				break;
			}
		}

		return (
			<div key={tenant.id} className='program-container'>
				<Sidebar.Pushable as={Segment}>

					<Sidebar as={Menu} inverted vertical direction='left' animation='overlay' duration={ANIMATION_DURATION} visible={this.state.menuVisible === 'board'} onHide={this.handleCloseMenus}>
						<BoardMenu tenant={tenant} user={this.state.user} history={this.props.history} onMenuClick={this.handleCloseMenus}/>
					</Sidebar>
					<Sidebar as={Menu} inverted vertical direction='left' animation='overlay' duration={ANIMATION_DURATION} visible={this.state.menuVisible === 'super'} onHide={this.handleCloseMenus}>
						<SuperMenu tenant={tenant} user={this.state.user} history={this.props.history} onMenuClick={this.handleCloseMenus}/>
					</Sidebar>

					<Sidebar as={Menu} inverted vertical direction='right' animation='overlay' duration={ANIMATION_DURATION} visible={this.state.menuVisible === 'tenant'} onHide={this.handleCloseMenus}>
						<TenantMenu tenant={tenant} user={this.state.user} history={this.props.history} onMenuClick={this.handleChangeTenant}/>
					</Sidebar>
					<Sidebar as={Menu} inverted vertical direction='right' animation='overlay' duration={ANIMATION_DURATION} visible={this.state.menuVisible === 'user'} onHide={this.handleCloseMenus}>
						<UserMenu tenant={tenant} user={this.state.user} history={this.props.history} onMenuClick={this.handleCloseMenus}/>
					</Sidebar>

					<Sidebar.Pusher dimmed={this.state.menuVisible === true}>
						<ProgramHeader
							title={title}
							tenant={tenant}
							user={this.state.user}
							onHomeClick={this.handleHomeClick}
							onBoardMenuClick={this.handleBoardMenuOpen}
							onSuperMenuClick={this.handleSuperMenuOpen}
							onTenantMenuClick={this.handleTenantMenuOpen}
							onUserMenuClick={this.handleUserMenuOpen}
						/>
						<LocatableView mode={mode} tenant={tenant} board={board} boardid={boardId} onTenantUpdate={this.handleTenantUpdate}/>
						{/*<Dimmer inverted active={this.state.boardMenuVisible || this.state.userMenuVisible} onClickOutside={this.handleCloseMenus}/>*/}
					</Sidebar.Pusher>

				</Sidebar.Pushable>
			</div>
		);

	}

}

/*
Props
board: The current board displayed

Handlers
onBoardMenuClick: Clicked to open the board menu
onUserMenuClick: Clicked to open the user menu
 */
class ProgramHeader extends React.Component {

	state = {
		tenants: []
	};

	componentDidMount() {
		this.loadTenants();
	}

	loadTenants = () => {
		tenantService.getTenants((tenants) => {
			this.setState({tenants: tenants});
		}, (failureMessage) => {
			console.log(failureMessage)
		});
	};

	render() {
		const isSuperUser = User.hasRole(this.props.user, Role.SUPER);
		const isAdminUser = User.hasRole(this.props.user, Role.ADMIN);

		return (
			<Segment color='blue' inverted>

				<Grid columns={4}>
					<Grid.Row>
						<Grid.Column textAlign='left' verticalAlign='middle' width={5}>
							<Icon name={HOME_ICON} size='big' link onClick={this.props.onHomeClick}/>
							<Icon name={BOARDS_ICON} size='big' link onClick={this.props.onBoardMenuClick}/>
							{isAdminUser && <Icon name={CONFIG_ICON} size='big' link onClick={this.props.onSuperMenuClick}/>}
						</Grid.Column>
						<Grid.Column textAlign='center' verticalAlign='middle' width={6}>
							<Header size='large' inverted>{this.props.title}</Header>
						</Grid.Column>
						<Grid.Column textAlign='right' verticalAlign='middle' width={5}>
							{isSuperUser && <Label as='a' onClick={this.props.onTenantMenuClick}>{this.props.tenant.name}</Label>}
							<Icon name={USER_ICON} size='big' link onClick={this.props.onUserMenuClick}/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		);
	}

}

const LocatableView = (props) => {
	const lastLocation = useLastLocation();
	const from = (lastLocation && lastLocation.pathname) || '/home';

	return (
		<View {...props} from={from}/>
	);

};

class View extends React.Component {

	render() {
		const mode = this.props.mode;
		const boardid = this.props.boardid;
		const tenant = this.props.tenant;
		const from = this.props.from;

		switch (mode) {
			case 'profile' : {
				return <Profile from={from}/>;
			}
			case 'password' : {
				return <Password from={from}/>;
			}
			case 'manage/boards' : {
				return <BoardManagementView tenant={tenant} from={from} onTenantUpdate={this.props.onTenantUpdate}/>
			}
			case 'manage/users' : {
				return <UserManagementView from={from}/>
			}
			case 'manage/tenants' : {
				return <TenantManagementView from={from}/>
			}
			case 'manage/stations' : {
				return <StationManagementView from={from}/>
			}
			default : {
				return boardid > 0 ? <Board key={boardid} boardid={boardid}/> : <div/>
			}
		}
	}
}
