import {ApiService} from "./ApiService";

export class MetricService extends ApiService {

	newMetric(metric, success, failure) {
		this.fetch(`${this.uri}/api/metrics`, {
			method: 'POST',
			body: JSON.stringify(metric)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	saveMetric(metric, success, failure) {
		this.fetch(`${this.uri}/api/metrics`, {
			method: 'PUT',
			body: JSON.stringify(metric)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	deleteMetricById(id, success, failure) {
		this.fetch(`${this.uri}/api/metrics/${id}`, {
			method: 'DELETE'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	updateMetricValue(metric, success, failure) {
		console.log("Set metric value to: " + metric.value);
		this.fetch(`${this.uri}/api/metrics/${metric.id}/value`, {
			method: 'PUT',
			body: JSON.stringify(metric)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}


}