import React, {Component} from 'react'
import {Icon, Menu} from 'semantic-ui-react'
import {ALERT_DISABLED_ICON, ALERT_ENABLED_ICON, ANIMATION_DURATION} from '../config'
import {boardService} from "../Api";

export default class BoardMenu extends Component {

	state = {
		boards: []
	};

	componentDidMount() {
		this.loadBoards();
		//this.refreshTimer = setInterval(this.loadBoards, 5000);
	}

	componentWillUnmount() {
		//clearInterval(this.refreshTimer);
	}

	loadBoards = () => {
		if (!this.props.tenant.id) return;

		boardService.getUserBoards(this.props.tenant.id, (boards) => {
			this.setState({boards: boards});
		}, (failureMessage) => {
			console.log(failureMessage);
		});
	};

	handleHomeSelected = () => {
		setTimeout(this.props.history.push, ANIMATION_DURATION, '/home');
		this.props.onMenuClick();
	};

	handleBoardSelected = (event, board) => {
		setTimeout(this.props.history.push, ANIMATION_DURATION, '/board?id=' + board.id);
		this.props.onMenuClick();
	};

	handleBoardMoved = (boards) => {
		this.setState({boards: boards})
	};

	render() {
		return (
			[
				this.state.boards.map((board, index) => (
						<BoardItem
							idx={index}
							key={board.id}
							id={board.id}
							board={board}
							name={board.name}
							onBoardSelected={this.handleBoardSelected}
							onBoardMoved={this.handleBoardMoved}
						/>
					)
				)
			]
		);
	}
}

class BoardItem extends Component {

	state = {
		alert: this.props.board.alert
	};

	handleMoveBoard = (sourceIndex, targetIndex) => {
		boardService.moveBoard(this.props.board.id, sourceIndex, targetIndex, (boards) => {
			//this.setState({metrics: metrics})
			this.props.onBoardMoved(boards);
		}, (message) => {
			console.log(message)
		})
	};

	handleBoardSelected = (event) => {
		this.props.onBoardSelected(event, this.props.board)
	};

	handleDragStart = (event) => {
		event.dataTransfer.effectAllowed = "move";
		event.dataTransfer.setData("text/plain", this.props.idx);
	};

	handleDragEnter = (event) => {
		event.preventDefault();
		//let sourceIndex = parseInt( event.dataTransfer.getData("text/plain") );
		//this.setState({hover: sourceIndex !== this.props.idx});
		this.setState({hover: true});
	};

	handleDragOver = (event) => {
		event.preventDefault();
		//let sourceIndex = event.target.props.idx;
		//console.log("source=" + sourceIndex + " target=" + this.props.idx);
		//this.setState({hover: sourceIndex !== this.props.idx});
	};

	handleDragLeave = (event) => {
		//event.preventDefault();
		//console.log("Drag leave: " + event);
		this.setState({hover: false});
	};

	handleDrop = (event) => {
		event.preventDefault();
		this.setState({hover: false});
		let sourceIndex = parseInt(event.dataTransfer.getData("text/plain"), 10);
		let targetIndex = this.props.idx;
		if (sourceIndex === targetIndex) return;

		// TODO This works well enough to call the API and move the metric
		console.log("Board " + sourceIndex + " dropped on: " + this.props.idx);
		this.handleMoveBoard(sourceIndex, targetIndex);
	};

	handleAlertClick = (event) => {
		//this.setState({alert: !this.state.alert})
		event.stopPropagation();
		// TODO Send change request
		let userboard = {
			boardId: this.props.board.id,
			alert: !this.state.alert
		}
		boardService.updateUserBoard(userboard, (board) => {
			this.setState({alert: board.alert})
		}, (failureMessage) => {
			console.log( "Unable to change alert state for " + this.props.board.name + ": " + failureMessage );
		} );
	}

	render() {
		let alertIcon = this.state.alert ? ALERT_ENABLED_ICON : ALERT_DISABLED_ICON;
		return (
			<Menu.Item
				draggable
				key={this.props.board.id}
				id={this.props.board.key}
				onClick={this.handleBoardSelected}
				onDragStart={this.handleDragStart} onDragEnter={this.handleDragEnter} onDragOver={this.handleDragOver} onDragLeave={this.handleDragLeave} onDrop={this.handleDrop}
			>{this.props.board.name}<Icon name={alertIcon} onClick={this.handleAlertClick}/></Menu.Item>
		);
	}

}
