import React, {Component} from 'react';
import {Button, Container, Form, Grid, Header, Icon, Input, Message, Modal, Segment} from 'semantic-ui-react'
import {tenantService} from '../Api'
import {EMAIL_PATTERN, PHONE_PATTERN, CANCEL_ICON, DELETE_ICON, SAVE_ICON, TENANT_ICON} from "../config";

export default class TenantManagementView extends Component {

	state = {
		redirectToReferrer: false,
		tenants: []
	};

	componentDidMount() {
		this.loadTenants();
		this.refreshTimer = setInterval(this.loadTenants, 2000);
	}

	componentWillUnmount() {
		clearInterval(this.refreshTimer);
	}

	loadTenants = () => {
		tenantService.getTenants((tenants) => (
			this.setState({tenants: tenants})
		), (failureMessage) => {
			console.log(failureMessage)
		});
	};

	handleCreateTenant = (tenant, success, failure) => {
		tenantService.newTenant(tenant, () => {
			success();
			this.loadTenants()
		}, () => {
			failure();
		});
	};

	handleUpdateTenant = (tenant, success, failure) => {
		tenantService.saveTenant(tenant, () => {
			success();
			this.loadTenants()
		}, () => {
			failure();
		});
		this.setState({editing: false});
	};

	handleDeleteTenant = (id) => {
		tenantService.deleteTenantById(id, () => {
			this.loadTenants()
		}, () => {
			this.loadTenants()
		});
	};

	render() {

		return (
			<TenantManagement
				tenants={this.state.tenants}
				onCreateTenant={this.handleCreateTenant}
				onUpdateTenant={this.handleUpdateTenant}
				onDeleteTenant={this.handleDeleteTenant}
			/>
		);

	}

}

class TenantManagement extends Component {

	state = {
		editing: false,
		tenant: {id: '', name: ''}
	};

	handleFormOpen = () => this.setState({tenant: {id: '', name: ''}, editing: true});

	handleFormClose = () => this.setState({editing: false});

	handleEditTenant = (tenant) => {
		this.setState({tenant: tenant, editing: true});
	};

	handleSaveTenant = (tenant, failure) => {
		if (tenant.id === '') {
			this.handleCreateTenant(tenant, failure)
		} else {
			this.handleUpdateTenant(tenant, failure)
		}
	};

	handleCreateTenant = (tenant, failure) => {
		this.props.onCreateTenant(tenant, () => {
			this.handleFormClose();
		}, failure)
	};

	handleUpdateTenant = (tenant, failure) => {
		this.props.onUpdateTenant(tenant, () => {
			this.handleFormClose();
		}, failure)
	};

	handleDeleteTenant = (id) => {
		this.props.onDeleteTenant(id)
	};

	handleCancelTenant = () => {
		this.handleFormClose();
	};

	render() {
		if (this.state.editing) {
			return (
				<Container>
					<TenantForm tenant={this.state.tenant} onSaveTenant={this.handleSaveTenant} onCancelTenant={this.handleCancelTenant}/>
				</Container>
			);
		} else {
			return (
				<Container>
					<TenantList
						tenants={this.props.tenants}
						onNewTenant={this.handleFormOpen}
						onEditTenant={this.handleEditTenant}
						onDeleteTenant={this.handleDeleteTenant}
					/>
				</Container>
			)
		}
	}
}

class TenantList extends Component {

	handleNewTenant = () => {
		this.props.onNewTenant()
	};

	handleEditTenant = (tenant) => {
		this.props.onEditTenant(tenant)
	};

	handleDeleteTenant = (id) => {
		this.props.onDeleteTenant(id)
	};

	render() {
		return (
			<Container>
				<Segment.Group>
					{
						this.props.tenants.map((tenant) => (
								// Probably because the key is the same, tenant property changes are not propagated
								// Adding the name to the key fixed the issue, but what about other properties?
								// Do I need to create a state hash to be put in the key?
								// Using the map index also does not work because it does not change.
								<Tenant key={tenant.id} tenant={tenant} onEditTenant={this.handleEditTenant} onDeleteTenant={this.handleDeleteTenant}/>
							)
						)
					}
				</Segment.Group>
				<Button icon='add' fluid onClick={this.handleNewTenant}/>
			</Container>
		);
	}

}

// class EditableTenant extends Component {
//
// 	state = {
// 		editing: false,
// 		tenant: {id: '', name: ''}
// 	};
//
// 	componentDidMount() {
// 		this.setState({tenant: this.props.tenant})
// 	}
//
// 	handleEditTenant = () => {
// 		this.setState({editing: true});
// 	};
//
// 	handleUpdateTenant = (tenant, failure) => {
// 		this.props.onUpdateTenant(tenant, () => {
// 			this.setState(state => ({tenant: {...state.tenant, ...tenant}, editing: false}));
// 		}, failure);
// 	};
//
// 	handleCancelTenant = () => {
// 		this.setState({editing: false});
// 	};
//
// 	handleDeleteTenant = () => {
// 		this.props.onDeleteTenant(this.props.tenant.id)
// 	};
//
// 	render() {
// 		if (this.state.editing) {
// 			return (
// 				<TenantForm tenant={this.state.tenant} onSaveTenant={this.handleUpdateTenant} onCancelTenant={this.handleCancelTenant}/>
// 			);
// 		} else {
// 			return (
// 				<Tenant tenant={this.state.tenant} onEditTenant={this.handleEditTenant} onDeleteTenant={this.handleDeleteTenant}/>
// 			)
// 		}
// 	}
// }
//
// class ToggleableTenantForm extends Component {
//
// 	state = {
// 		editing: false
// 	};
//
// 	handleFormOpen = () => this.setState({editing: true});
//
// 	handleFormClose = () => this.setState({editing: false});
//
// 	handleNewTenant = (tenant, failure) => {
// 		this.props.onNewTenant(tenant, () => {
// 			this.handleFormClose();
// 		}, failure);
// 	};
//
// 	handleCancelTenant = () => this.handleFormClose();
//
// 	render() {
// 		if (this.state.editing) {
// 			return (
// 				<TenantForm tenant={{}} onSaveTenant={this.handleNewTenant} onCancelTenant={this.handleCancelTenant}/>
// 			)
// 		} else {
// 			return (
// 				<Table.Row>
// 					<Table.Cell colSpan='100'>
// 						<Button icon='add' fluid onClick={this.handleFormOpen}/>
// 					</Table.Cell>
// 				</Table.Row>
// 			)
// 		}
// 	}
//
// }

class Tenant extends Component {

	handleEditTenant = () => this.props.onEditTenant(this.props.tenant);

	handleDeleteTenant = () => this.props.onDeleteTenant(this.props.tenant.id);

	render() {
		return (
			<Segment>
				<Grid columns={5}>
					<Grid.Row>
						<Grid.Column width={5}>
							<Header style={{cursor: 'pointer'}} onClick={this.handleEditTenant}><Icon name={TENANT_ICON}/>{this.props.tenant.name}</Header>
						</Grid.Column>
						<Grid.Column width={4}>
							{this.props.tenant.address}
						</Grid.Column>
						<Grid.Column textAlign='center' width={3}>
							{this.props.tenant.phone}
						</Grid.Column>
						<Grid.Column width={3}>
							{this.props.tenant.email}
						</Grid.Column>
						<Grid.Column textAlign='right' width={1}>
							<DeleteTenantDialog tenant={this.props.tenant} onDeleteTenant={this.handleDeleteTenant}/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		)
	}

}

class TenantForm extends Component {

	state = {
		id: this.props.tenant.id || '',
		name: this.props.tenant.name || '',
		address: this.props.tenant.address || '',
		phone: this.props.tenant.phone || '',
		phoneError: false,
		email: this.props.tenant.email || '',
		emailError: false,
	};

	handleFieldChange = (event) => {
		this.setState({[event.target.name]: event.target.value});
		if (event.target.name === 'phone') this.setState({phoneError: PHONE_PATTERN.test(event.target.value)});
		if (event.target.name === 'email') this.setState({emailError: !(event.target.value === "" || EMAIL_PATTERN.test(event.target.value))});
	};

	checkForEnter = (event) => {
		if (event.key === 'Enter') this.handleSaveTenant();
	};

	handleSaveTenant = () => {
		this.props.onSaveTenant(
			{
				id: this.state.id,
				name: this.state.name,
				address: this.state.address,
				phone: this.state.phone,
				email: this.state.email,
				errorMessage: '',
			},
			() => {
				this.setState({errorMessage: 'Failed to save client information'})
			}
		)
	};

	handleCancelTenant = () => {
		this.props.onCancelTenant()
	};

	render() {
		return (
			<Segment>
				<Grid>
					<Grid.Row>
						<Grid.Column verticalAlign='middle'>
							<Icon name={TENANT_ICON} size='big'/>
						</Grid.Column>
						<Grid.Column verticalAlign='middle' width={13}>
							<Header>{this.state.name}</Header>
						</Grid.Column>
						<Grid.Column floated='right' textAlign='right' verticalAlign='middle'>
							<Icon name={SAVE_ICON} link size='big' onClick={this.handleSaveTenant} color='green'/>
						</Grid.Column>
						<Grid.Column floated='right' textAlign='right' verticalAlign='middle'>
							<Icon name={CANCEL_ICON} link size='large' onClick={this.handleCancelTenant}/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Form error={this.state.errorMessage !== ''}>
								<Message error header={this.state.errorMessage}/>
								<Form.Group>
									<Form.Field name='name' width={4} control={Input} required fluid label='Client Name' placeholder='Name' value={this.state.name} onChange={this.handleFieldChange} onKeyDown={this.checkForEnter}/>
									<Form.Field name='address' width={5} control={Input} fluid label='Address' placeholder='Address' value={this.state.address} onChange={this.handleFieldChange} onKeyDown={this.checkForEnter}/>
									<Form.Field name='phone' width={3} control={Input} fluid label='Phone Number' placeholder='000-000-0000' value={this.state.phone} onChange={this.handleFieldChange} onKeyDown={this.checkForEnter} error={this.state.phoneError}/>
									<Form.Field name='email' width={4} control={Input} fluid label='Email Address' placeholder='name@example.com' value={this.state.email} onChange={this.handleFieldChange} onKeyDown={this.checkForEnter} error={this.state.emailError}/>
								</Form.Group>
							</Form>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		)
	}

}

class DeleteTenantDialog extends Component {
	state = {open: false};

	handleOpen = () => this.setState({open: true});

	handleClose = () => this.setState({open: false});

	handleDelete = () => {
		this.handleClose();
		this.props.onDeleteTenant();
	};

	render() {
		return (
			<Modal size='mini' centered={false} open={this.state.open} onClose={this.handleClose} trigger={<Icon name={DELETE_ICON} size='large' link onClick={this.handleOpen}/>}>
				<Modal.Header>Delete Client</Modal.Header>
				<Modal.Content>
					<Header><Icon name={TENANT_ICON}/>{this.props.tenant.name}</Header>
					<p>Are you sure you want to permanently delete this client?</p>
				</Modal.Content>
				<Modal.Actions>
					<Button negative content='No' onClick={this.handleClose}/>
					<Button positive content='Yes' onClick={this.handleDelete}/>
				</Modal.Actions>
			</Modal>
		);
	}

}
