import React from "react";
import {Form} from "semantic-ui-react";
import {stationService} from "../../Api";

export class DeviceMetricEditor extends React.Component {

	state = {
		devices: [],
		stationLookup: [],
		deviceLookup: [],
		stationId: (this.props.metric.station && this.props.metric.station.id) || '',
		deviceId: (this.props.metric.device && this.props.metric.device.id) || ''
	};

	constructor(...args) {
		super(...args);
		this.getStateToSave = this.getStateToSave.bind(this);
	}

	componentDidMount() {
		this.loadStations();
		if (this.props.metric.station) this.loadDevices(this.props.metric.station.id);
	}

	loadStations = () => {
		stationService.getStations((stations) => {
		//tenantService.getStations(this.props.metric.board.tenant.id, (stations) => {
			this.setState({
				stationLookup: stations.map((station) => {
					return {'key': station.id, 'value': station.id, 'text': station.name}
				})
			});
		}, (message) => {
			console.log(message);
		});
	};

	loadDevices = (stationId) => {
		if (stationId === undefined) return;

		stationService.getDevices(stationId, (devices) => {
			this.setState({
				devices: devices,
				deviceLookup: devices.map((device) => {
					const name = device.tag ? '[' + device.tag + '] ' + device.name : device.name;
					return {'key': device.id, 'value': device.id, 'text': name}
				})
			})
		}, (message) => {
			console.log(message);
		});
	};

	handleFieldChange = (event, {name, value}) => {
		// This method expects the component name to match the state key name
		switch (name) {
			case 'stationId' : {
				this.loadDevices(value);
				break;
			}
			case 'deviceId' : {
				const oldDevice = this.state.devices.find((device) => {
					return device.id === this.state.deviceId;
				});
				const newDevice = this.state.devices.find((device) => {
					return device.id === value;
				});

				this.props.onNameChange(newDevice.name, oldDevice && oldDevice.name);

				break;
			}
			default : {
				break;
			}
		}

		this.setState({[name]: value});
	};

	getStateToSave() {
		return {
			station: {id: this.state.stationId},
			device: {id: this.state.deviceId}
		}
	};

	render() {
		return (
			<Form.Group>
				<Form.Field name='stationId' width={8} control={Form.Select} fluid label='Station' placeholder='Station' value={this.state.stationId}
										options={this.state.stationLookup} onChange={this.handleFieldChange}/>
				<Form.Field name='deviceId' width={8} control={Form.Select} fluid label='Device' placeholder='Device' value={this.state.deviceId}
										options={this.state.deviceLookup} onChange={this.handleFieldChange}/>
			</Form.Group>
		)
	}

}