import {ApiService} from "./ApiService";

export class GraphService extends ApiService {

	getGraphSeries(id, success, failure ) {
		this.fetch(`${this.uri}/api/graph/${id}`, {
			method: 'GET',
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getGraphCsv(id, success, failure ) {
		this.download(`${this.uri}/api/graph/csv/${id}`, 'text/csv', {
			method: 'GET',
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getSeries(id, success, failure ) {
		this.fetch(`${this.uri}/api/graphs/${id}/series`, {
			method: 'GET',
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	newSeries(series, success, failure) {
		this.fetch(`${this.uri}/api/series`, {
			method: 'POST',
			body: JSON.stringify(series)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	saveSeries(series, success, failure) {
		this.fetch(`${this.uri}/api/series`, {
			method: 'PUT',
			body: JSON.stringify(series)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	deleteSeriesById(id, success, failure) {
		this.fetch(`${this.uri}/api/series/${id}`, {
			method: 'DELETE'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

}