import React from 'react';
import {Button, Checkbox, Container, Form, Grid, Header, Icon, Message, Modal, Segment} from 'semantic-ui-react'
import {boardService} from '../Api';
import MetricManagement from './MetricManagement';
import {BOARD_ICON, CANCEL_ICON, DELETE_ICON, SAVE_ICON} from '../config'

export default class BoardManagementView extends React.Component {

	state = {
		redirectToReferrer: false,
		boards: []
	};

	componentDidMount() {
		this.loadBoards();
		this.refreshTimer = setInterval(this.loadBoards, 5000);
	}

	componentWillUnmount() {
		clearInterval(this.refreshTimer);
	}

	loadBoards = () => {
		if (!this.props.tenant.id) return;

		boardService.getBoards(this.props.tenant.id, (boards) => (
			this.setState({boards: boards})
		), (failureMessage) => {
			console.log(failureMessage)
		});

		this.props.onTenantUpdate();
	};

	handleCreateBoard = (board, success, failure) => {
		boardService.newBoard(board, () => {
			success();
			this.loadBoards()
		}, () => {
			failure();
		});
	};

	handleUpdateBoard = (board, success, failure) => {
		boardService.saveBoard(board, () => {
			success();
			this.loadBoards()
		}, () => {
			failure();
		});
		this.setState({editing: false});
	};

	handleDeleteBoard = (id) => {
		boardService.deleteBoardById(id, () => {
			this.loadBoards()
		}, () => {
			this.loadBoards()
		});
	};

	render() {
		return (
			<BoardManagement
				tenant={this.props.tenant}
				boards={this.state.boards}
				onCreateBoard={this.handleCreateBoard}
				onUpdateBoard={this.handleUpdateBoard}
				onDeleteBoard={this.handleDeleteBoard}
			/>
		)
	}

}

class BoardManagement extends React.Component {

	state = {
		editing: false,
		board: {}
	};

	handleFormOpen = () => this.setState(
		{
			board:
				{
					id: '',
					name: '',
					description: '',
					enabled: true,
					tenant: this.props.tenant,
					metrics: []
				}
			, editing: true
		}
	);

	handleFormClose = () => this.setState({editing: false});

	handleEditBoard = (board) => {
		this.setState({board: board, editing: true});
	};

	handleSaveBoard = (board, failure) => {
		if (board.id === '') {
			this.handleCreateBoard(board, failure)
		} else {
			this.handleUpdateBoard(board, failure)
		}
	};

	handleCreateBoard = (board, failure) => {
		this.props.onCreateBoard(board, () => {
			this.handleFormClose();
		}, failure);
	};

	handleUpdateBoard = (board, failure) => {
		this.props.onUpdateBoard(board, () => {
			this.handleFormClose();
		}, failure);
	};

	handleDeleteBoard = (id) => {
		this.props.onDeleteBoard(id)
	};

	handleCancelBoard = () => {
		this.handleFormClose();
	};

	render() {
		if (this.state.editing) {
			return (
				<Container>
					<BoardForm board={this.state.board} tenants={this.props.tenants} timezones={this.props.timezones} onSaveBoard={this.handleSaveBoard} onCancelBoard={this.handleCancelBoard}/>
				</Container>
			);
		} else {
			return (
				<Container>
					<BoardList
						boards={this.props.boards}
						onNewBoard={this.handleFormOpen}
						onEditBoard={this.handleEditBoard}
						onDeleteBoard={this.handleDeleteBoard}
					/>
				</Container>
			);
		}
	}
}

class BoardList extends React.Component {

	handleNewBoard = () => {
		this.props.onNewBoard()
	};

	handleEditBoard = (board) => {
		this.props.onEditBoard(board)
	};

	handleDeleteBoard = (id) => {
		this.props.onDeleteBoard(id)
	};

	render() {
		return (
			<Container>
				<Segment.Group>
					{
						this.props.boards.map((board) => (
								// Probably because the key is the same, board property changes are not propagated
								// Adding the name to the key fixed the issue, but what about other properties?
								// Do I need to create a state hash to be put in the key?
								// Using the map index also does not work because it does not change.
								<Board key={board.id} board={board} onEditBoard={this.handleEditBoard} onDeleteBoard={this.handleDeleteBoard}/>
							)
						)
					}
				</Segment.Group>
				<Button icon='add' fluid onClick={this.handleNewBoard}/>
			</Container>
		);
	}

}

class Board extends React.Component {

	handleEditBoard = () => this.props.onEditBoard(this.props.board);

	handleDeleteBoard = () => this.props.onDeleteBoard(this.props.board.id);

	render() {
		return (
			<Segment>
				<Grid columns={4}>
					<Grid.Row>
						<Grid.Column width={7}>
							<Header style={{cursor: 'pointer'}} onClick={this.handleEditBoard}><Icon name={BOARD_ICON} disabled={!this.props.board.enabled}/>{this.props.board.name}</Header>
						</Grid.Column>
						<Grid.Column width={8}>
							{this.props.board.description}
						</Grid.Column>
						<Grid.Column textAlign='right' width={1}>
							<DeleteBoardDialog board={this.props.board} onDeleteBoard={this.handleDeleteBoard}/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		)
	}

}

class BoardForm extends React.Component {

	state = {
		id: this.props.board.id || '',
		name: this.props.board.name || '',
		description: this.props.board.description || '',
		tenant: this.props.board.tenant.id || '',
		enabled: this.props.board.enabled,
		editingMetric: false,
		errorMessage: '',
	};

	handleFieldChange = (event) => {
		this.setState({[event.target.name]: event.target.value})
	};

	handleCheckChange = (event, {name, checked}) => {
		this.setState({[name]: checked});
	};

	handleSelectChange = (event, {name, value}) => {
		this.setState({[name]: value});
	};

	checkForEnter = (event) => {
		if (event.key === 'Enter') this.handleSaveBoard();
	};

	handleSaveBoard = () => {
		this.props.onSaveBoard(
			{
				id: this.state.id,
				name: this.state.name,
				description: this.state.description,
				tenant: {id: this.state.tenant},
				enabled: this.state.enabled,
			},
			() => {
				this.setState({errorMessage: 'Failed to save board configuration'})
			}
		)
	};

	handleCancelBoard = () => {
		this.props.onCancelBoard()
	};

	handleEditMetric = (editingMetric ) => {
		this.setState( {editingMetric: editingMetric })
	};

	render() {
		return (
			<Segment>
				<Grid>
					<Grid.Row verticalAlign='middle'>
						<Grid.Column>
							<Icon name={BOARD_ICON} size='big'/>
						</Grid.Column>
						<Grid.Column width={11}>
							<Header>{this.state.name}</Header>
						</Grid.Column>
						<Grid.Column width={2}>
							<Checkbox name='enabled' toggle checked={this.state.enabled} onClick={this.handleCheckChange}/>
						</Grid.Column>
						<Grid.Column floated='right' textAlign='right'>
							{this.state.editingMetric && <Icon name={SAVE_ICON} link size='big' color='grey' disabled/>}
							{!this.state.editingMetric && <Icon name={SAVE_ICON} link size='big' color='green' onClick={this.handleSaveBoard}/>}
						</Grid.Column>
						<Grid.Column floated='right' textAlign='right'>
							<Icon name={CANCEL_ICON} link size='large' onClick={this.handleCancelBoard}/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row verticalAlign='middle'>
						<Grid.Column>
							<Form error={this.state.errorMessage !== ''}>
								<Message error header={this.state.errorMessage}/>
								<Form.Group>
									<Form.Field name='name' width={7} control={Form.Input} required fluid label='Board Name' placeholder='Name' value={this.state.name} onChange={this.handleFieldChange} onKeyDown={this.checkForEnter}/>
									<Form.Field name='description' width={9} control={Form.Input} fluid label='Description' placeholder='' value={this.state.description} onChange={this.handleFieldChange} onKeyDown={this.checkForEnter}/>
								</Form.Group>
							</Form>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<MetricManagement board={this.props.board} onEditMetric={this.handleEditMetric}/>
			</Segment>
		)
	}

}

class DeleteBoardDialog extends React.Component {

	state = {open: false};

	handleOpen = () => this.setState({open: true});

	handleClose = () => this.setState({open: false});

	handleDelete = () => {
		this.handleClose();
		this.props.onDeleteBoard();
	};

	render() {
		return (
			<Modal size='mini' centered={false} open={this.state.open} onClose={this.handleClose} trigger={<Icon name={DELETE_ICON} size='large' link onClick={this.handleOpen}/>}>
				<Modal.Header>Delete Board</Modal.Header>
				<Modal.Content>
					<Header><Icon name={BOARD_ICON}/>{this.props.board.name}</Header>
					<p>Are you sure you want to permanently delete this board?</p>
				</Modal.Content>
				<Modal.Actions>
					<Button negative content='No' onClick={this.handleClose}/>
					<Button positive content='Yes' onClick={this.handleDelete}/>
				</Modal.Actions>
			</Modal>
		);
	}

}
