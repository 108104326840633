import React from 'react';
import {Container, Header, Icon, Segment} from 'semantic-ui-react'
import {boardService} from "./Api";
import {ANIMATION_DURATION} from './config';
import {MetricType} from './metric/MetricType'
import Separator from './metric/Separator'
import DeviceMetric from './metric/DeviceMetric'
import GraphMetric from "./metric/GraphMetric";

/*
Props
metrics: The list of metrics for this view
 */
export default class Board extends React.Component {

	state = {
		board: {id: -1, metrics: []}
	};

	componentDidMount() {
		this.refreshTimer = setInterval(() => this.refreshBoard(this.props.boardid), 5000);
		setTimeout(() => this.refreshBoard(this.props.boardid), ANIMATION_DURATION);
	}

	componentWillUnmount() {
		clearInterval(this.refreshTimer);
		clearInterval(this.waitingTimer);
	}

	refreshBoard = (id) => {
		if (this.state.board.id <= 0) this.waitingTimer = setInterval(() => this.setState({board: {id: 0}}), 500);

		boardService.getBoardAndMetrics(id, (response) => {
			//console.log(JSON.stringify(response));
			this.setState({board: response});
			clearInterval(this.waitingTimer);
		}, (message) => {
			//console.log(message);
			this.setState({board: undefined})
		});
	};

	handleRefreshBoard = () => {
		this.refreshBoard(this.state.board.id)
	};

	render() {
		if (this.state.board === undefined) {
			return (
				<Header align='center'>Board Not Found</Header>
			);
		}

		if (this.state.board.id > 0) {
			return (
				<Container>
					<Segment.Group>
						{this.state.board.metrics.map((metric) => <Metric key={metric.id} metric={metric} onRefreshBoard={this.handleRefreshBoard}/>)}
					</Segment.Group>
				</Container>
			)
		} else if (this.state.board.id === 0) {
			return (
				<Container textAlign='center'>
					<Icon loading size='massive' color='grey' name='spinner'/>
				</Container>
			)
		} else if (this.state.board.id === -1) {
			return (<div/>)
		}
	}

}

/*
Props
name: The name of the device
value: The value of the device
unit: The unit of the value

TODO Enhance for devices that can be updated

Handlers

*/
export class Metric extends React.Component {

	generateMetric() {
		switch (this.props.metric.type.name) {
			case MetricType.BASIC :
				return <DeviceMetric key={this.props.metric.id} metric={this.props.metric} onRefreshBoard={this.props.onRefreshBoard}/>;
			case MetricType.GRAPH :
				return <GraphMetric key={this.props.metric.id} metric={this.props.metric}/>;
			case MetricType.GRID_BUTTON :
				return <Separator key={this.props.metric.id} metric={{name:'Button Grid Metric'}}/>;
			case MetricType.SEPARATOR :
				return <Separator key={this.props.metric.id} metric={this.props.metric}/>;
			default:
				return <Separator key={this.props.metric.id} metric={{name:'Unknown Metric Type'}}/>;
		}
	}

	render() {
		return (this.generateMetric());
	}

}
