import {API_URL} from "../config";
import {authService} from '../Api';

export class ApiService {

	// Initializing important variables
	constructor() {
		// API server domain
		this.uri = `${API_URL}`;

		// React bindings
		this.fetch = this.fetch.bind(this);
		this.upload = this.upload.bind(this);
	}

	fetch(url, options) {
		// performs api calls sending the required authentication headers
		if (!!!options.headers) options.headers = {}
		options.headers['Accept'] = 'application/json'
		options.headers['Content-Type'] = 'application/json'
		if (authService.loggedIn()) options.headers['Authorization'] = 'Bearer ' + authService.getToken();

		//console.log("headers=" + JSON.stringify(headers));

		return fetch(url, options)
			.then(this.checkStatus)
			.then(response => response.json())
	}

	download(url, mediaType, options) {
		// performs api calls sending the required authentication headers
		let headers = {
			'Accept': mediaType,
			'Content-Type': mediaType,
		};

		// Setting Authorization header
		// Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
		if (authService.loggedIn()) headers['Authorization'] = 'Bearer ' + authService.getToken();

		//console.log("headers=" + JSON.stringify(headers));
		return fetch(url, {
			headers,
			...options
		})
			.then(this.checkStatus)
			.then(response => response.blob())
	}

	saveAs(blob, filename) {
		const a = document.createElement('a');
		const url = URL.createObjectURL(blob);
		a.href = url;
		a.download = filename || 'download';

		const blobUrlRevokeHandler = () => {
			setTimeout(() => {
				URL.revokeObjectURL(url);
				a.removeEventListener('click', blobUrlRevokeHandler);
			}, 150);
		};

		a.addEventListener('click', blobUrlRevokeHandler, false);
		a.click();
	}

	// Raises an error in case response status is not a success
	checkStatus(response) {
		// Success status lies between 200 to 299
		if (response.ok) return response;

		if (response.status === 403) {
			console.log("HTTP 403 - " + response.url);
			authService.reauthenticate();
			return response;
		}

		//Error condition
		const error = new Error(response.status);
		error.message = response.message;
		//error.response = response;
		throw error
	}

	upload(url, file, progress) {
		return new Promise((resolve, reject) => {
			const request = new XMLHttpRequest();

			request.addEventListener("progress", event => {
				progress(event);
			});

			request.addEventListener("load", event => {
				if (request.status === 200) {
					resolve(request.response);
				} else {
					reject(request.response);
				}
			});

			request.addEventListener("error", event => {
				reject(request.response);
			});

			const formData = new FormData();
			formData.append("file", file, file.name);

			request.open("POST", url);
			request.setRequestHeader('Authorization', 'Bearer ' + authService.getToken());
			request.send(formData);

			console.log("Upload sent!")
		});
	}

}