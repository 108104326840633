import {ApiService} from "./ApiService";

export class UserService extends ApiService {

	getUsers(success, failure) {
		this.fetch(`${this.uri}/api/users`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getUser( id, success, failure ) {
		this.fetch(`${this.uri}/api/users/${id}`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	newUser(user, success, failure) {
		this.fetch(`${this.uri}/api/users`, {
			method: 'POST',
			body: JSON.stringify(user)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	saveUser(user, success, failure) {
		this.fetch(`${this.uri}/api/users`, {
			method: 'PUT',
			body: JSON.stringify(user)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	deleteUserById(id, success, failure) {
		this.fetch(`${this.uri}/api/users/${id}`, {
			method: 'DELETE'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getUserProfile(success, failure) {
		this.fetch(`${this.uri}/api/profile`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	saveProfile(profile, success, failure) {
		this.fetch(`${this.uri}/api/profile`, {
			method: 'PUT',
			body: JSON.stringify(profile)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	savePassword(profile, success, failure) {
		this.fetch(`${this.uri}/api/profile/password`, {
			method: 'PUT',
			body: JSON.stringify(profile)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

}