import React from "react";
import {Button, Container, Form, Grid, Header, Icon, Modal} from "semantic-ui-react";
import {GraphSeriesEditor} from "./GraphSeriesEditor";
import {graphService, stationService} from "../../Api";
import {SERIES_ICON} from "../../config";

export class GraphMetricEditor extends React.Component {

	state = {
		id: this.props.metric.id || '',
		width: this.props.metric.width || '',
		height: this.props.metric.height || '',
		sampletime: this.props.metric.sampletime || '',
		timeaxislabel: this.props.metric.timeaxislabel || '',
		valueaxislabel: this.props.metric.valueaxislabel || '',
		timeaxisspan: this.props.metric.timeaxisspan || '',
		timeaxislimits: this.props.metric.timeaxislimits || false,
		timeaxislimit1: this.props.metric.timeaxislimit1 || '',
		timeaxislimit2: this.props.metric.timeaxislimit2 || '',
		valueaxislimit1: this.props.metric.valueaxislimit1 === undefined ? '' : this.props.metric.valueaxislimit1,
		valueaxislimit2: this.props.metric.valueaxislimit2 === undefined ? '' : this.props.metric.valueaxislimit2,
	};

	constructor(...args) {
		super(...args);
		this.getStateToSave = this.getStateToSave.bind(this);
	}

	handleFieldChange = (event, {name, value}) => {
		// This method expects the component name to match the state key name
		this.setState({[name]: value});
	};

	handleCheckChange = (event, {name, checked}) => {
		// This method expects the component name to match the state key name
		this.setState({[name]: checked});
	};

	getStateToSave() {
		return {
			width: this.state.width,
			height: this.state.height,
			sampletime: this.state.sampletime,
			timeaxislabel: this.state.timeaxislabel,
			valueaxislabel: this.state.valueaxislabel,
			timeaxisspan: this.state.timeaxisspan,
			timeaxislimits: this.state.timeaxislimits,
			timeaxislimit1: this.state.timeaxislimit1,
			timeaxislimit2: this.state.timeaxislimit2,
			valueaxislimit1: this.state.valueaxislimit1,
			valueaxislimit2: this.state.valueaxislimit2,
		}
	};

	render() {
		return (
			<div>
				<Form.Group>
					<Form.Field name='width' width={4} control={Form.Input} required fluid label='Width' placeholder='Pixels or Percent' value={this.state.width} onChange={this.handleFieldChange}/>
					<Form.Field name='height' width={4} control={Form.Input} required fluid label='Height' placeholder='Pixels or Percent' value={this.state.height} onChange={this.handleFieldChange}/>
					<Form.Field name='valueaxislimit1' width={4} control={Form.Input} fluid label='Value Axis Limit' placeholder='0.0' value={this.state.valueaxislimit1} onChange={this.handleFieldChange}/>
					<Form.Field name='valueaxislimit2' width={4} control={Form.Input} fluid label='Value Axis Limit' placeholder='0.0' value={this.state.valueaxislimit2} onChange={this.handleFieldChange}/>
				</Form.Group>
				<Form.Group>
					<Form.Field name='timeaxisspan' width={4} control={Form.Input} fluid label='Time Axis Span' placeholder='e.g. 75m, 24h, 1d' value={this.state.timeaxisspan} disabled={this.state.timeaxislimits} onChange={this.handleFieldChange}/>
					<Form.Checkbox name='timeaxislimits' width={4} toggle label='Use Time Limits' checked={this.state.timeaxislimits} onClick={this.handleCheckChange}/>
					<Form.Field name='timeaxislimit1' width={4} control={Form.Input} fluid label='Time Axis Limit' placeholder='yyyy-MM-dd hh:mm:ss' value={this.state.timeaxislimit1} disabled={!this.state.timeaxislimits}
											onChange={this.handleFieldChange}/>
					<Form.Field name='timeaxislimit2' width={4} control={Form.Input} fluid label='Time Axis Limit' placeholder='yyyy-MM-dd hh:mm:ss' value={this.state.timeaxislimit2} disabled={!this.state.timeaxislimits}
											onChange={this.handleFieldChange}/>
				</Form.Group>
				<Form.Group>
					<Form.Field name='sampletime' width={4} control={Form.Input} fluid label='Sample Time' placeholder='e.g. 75m, 24h, 1d' value={this.state.sampletime} onChange={this.handleFieldChange}/>
					<Form.Field width={4}/>
					<Form.Field name='valueaxislabel' width={4} control={Form.Input} fluid label='Value Axis Label' value={this.state.valueaxislabel} onChange={this.handleFieldChange}/>
					<Form.Field name='timeaxislabel' width={4} control={Form.Input} fluid label='Time Axis Label' value={this.state.timeaxislabel} onChange={this.handleFieldChange}/>
				</Form.Group>
				<GraphSeriesManagement metric={this.props.metric} onEditChild={this.props.onEditChild}/>
			</div>
		)
	}

}

class GraphSeriesManagement extends React.Component {

	state = {
		series: [],
		editing: false,
		seriesBeingEdited: {
			name: '',
			station: {id: 0, name: ''},
			device: {id: 0, name: ''},
			scale: 1.0,
			offset: 0.0
		},
		stationLookup: []
	};

	componentDidMount() {
		this.loadSeries();
		this.loadStations();
	}

	loadSeries = () => {
		graphService.getSeries(this.props.metric.configid, (series) => {
			this.setState({series: series});
		}, (message) => {
			console.log(message);
		});
	};

	loadStations = () => {
		stationService.getStations((stations) => {
			//tenantService.getStations(this.props.metric.board.tenant.id, (stations) => {
			this.setState({
				stationLookup: stations.map((station) => {
					return {'key': station.id, 'value': station.id, 'text': station.name}
				})
			});
		}, (message) => {
			console.log(message);
		});
	};

	handleFormOpen = () => {
		this.props.onEditChild(true);
		this.setState(
			{
				editing: true,
				seriesBeingEdited: {name: '', graph: {id: this.props.metric.configid, name: ''}, station: {id: 0, name: ''}, device: {id: 0, name: ''}, scale: 1.0, offset: 0.0},
			}
		);
	};

	handleFormClose = () => {
		this.props.onEditChild(false);
		this.setState({editing: false});
	};

	handleEditSeries = (series) => {
		this.props.onEditChild(true);
		this.setState({seriesBeingEdited: series, editing: true});
	};

	handleSaveSeries = (series) => {
		const success = () => {
		};

		const failure = () => {
			console.log("Failed to save series configuration!");
		};

		if (series.id === '') {
			this.handleCreateSeries(series, success, failure)
		} else {
			this.handleUpdateSeries(series, success, failure)
		}
	};

	handleCreateSeries = (series, success, failure) => {
		graphService.newSeries(series, () => {
			this.handleFormClose();
			this.loadSeries();
			success();
		}, () => {
			failure();
		});
	};

	handleUpdateSeries = (series, success, failure) => {
		graphService.saveSeries(series, () => {
			this.handleFormClose();
			this.loadSeries();
			success();
		}, () => {
			failure();
		});
	};

	handleDeleteSeries = (series, success, failure) => {
		graphService.deleteSeriesById(series.id, () => {
			this.loadSeries();
			success();
		}, (message) => {
			console.log(message);
			failure();
		});
	};

	handleCancelSeries = () => {
		this.handleFormClose();
	};

	handleMoveSeries = () => {
		//
	};

	render() {
		if (this.state.editing) {
			return (
				<Container>
					<GraphSeriesEditor series={this.state.seriesBeingEdited} stationLookup={this.state.stationLookup} onSaveSeries={this.handleSaveSeries} onCancelSeries={this.handleCancelSeries}/>
				</Container>
			);
		} else {
			return (
				<Container>
					<GraphSeriesList
						series={this.state.series}
						stations={this.state.stations}
						onNewSeries={this.handleFormOpen}
						onEditSeries={this.handleEditSeries}
						onDeleteSeries={this.handleDeleteSeries}
						onMoveSeries={this.handleMoveSeries}
					/>
				</Container>
			);
		}
	}
}

class GraphSeriesList extends React.Component {

	render() {
		return (
			<Grid>
				<Grid.Row>
					<Grid.Column width={16}>
						<Button icon='add' fluid onClick={this.props.onNewSeries}/>
					</Grid.Column>
				</Grid.Row>

				{
					this.props.series.map((series, index) => (
							// Probably because the key is the same, device property changes are not propagated
							// Adding the name to the key fixed the issue, but what about other properties?
							// Do I need to create a state hash to be put in the key?
							// Using the map index also does not work because it does not change.
							<Series id={series.id} key={index} series={series} onEditSeries={this.props.onEditSeries} onDeleteSeries={this.props.onDeleteSeries} onMoveSeries={this.props.onMoveSeries}/>
						)
					)
				}

				<Grid.Row>
					<Grid.Column width={16}>
						<Button icon='add' fluid onClick={this.props.onNewSeries}/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		)
	}

}

class Series extends React.Component {

	handleEditSeries = () => this.props.onEditSeries(this.props.series);

	handleDeleteSeries = () => this.props.onDeleteSeries(this.props.series);

	render() {
		return (
			<Grid.Row>
				<Grid.Column width={5}>
					<Header style={{cursor: 'pointer'}} onClick={this.handleEditSeries}>
						<Icon name={SERIES_ICON}/>
						{this.props.series.name}
					</Header>
				</Grid.Column>
				<Grid.Column width={3}>{this.props.series.station.name}</Grid.Column>
				<Grid.Column width={3}>{this.props.series.device.name}</Grid.Column>
				<Grid.Column width={2}>{this.props.series.scale}</Grid.Column>
				<Grid.Column width={2}>{this.props.series.offset}</Grid.Column>
				<Grid.Column textAlign='right' width={1}>
					<DeleteSeriesDialog series={this.props.series} onDeleteSeries={this.handleDeleteSeries}/>
				</Grid.Column>
			</Grid.Row>
		)
	}

}

class DeleteSeriesDialog extends React.Component {

	state = {open: false};

	handleOpen = () => this.setState({open: true});

	handleClose = () => this.setState({open: false});

	handleDelete = () => {
		this.handleClose();
		this.props.onDeleteSeries();
	};

	render() {
		return (
			<Modal size={'mini'} centered={false} open={this.state.open} onClose={this.handleClose} trigger={<Icon name={'trash'} size={'large'} link onClick={this.handleOpen}/>}>
				<Modal.Header>Delete Series</Modal.Header>
				<Modal.Content>
					<Header><Icon name='plug'/>{this.props.series.name}</Header>
					<p>Are you sure you want to permanently delete this series?</p>
				</Modal.Content>
				<Modal.Actions>
					<Button negative content={'No'} onClick={this.handleClose}/>
					<Button positive content={'Yes'} onClick={this.handleDelete}/>
				</Modal.Actions>
			</Modal>
		);
	}

}
