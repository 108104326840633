import React, {Component} from 'react'
import {Menu} from 'semantic-ui-react'
import {tenantService} from "../Api";

export default class TenantMenu extends Component {

	state = {
		tenants: []
	};

	componentDidMount() {
		this.loadTenants();
	}

	loadTenants = () => {
		tenantService.getTenants((tenants) => {
			this.setState({tenants: tenants});
		}, (failureMessage) => {
			console.log(failureMessage)
		});
	};

	handleTenantSelected = (event, tenant) => {
		this.props.onMenuClick(tenant.id);
	};

	render() {
		return (
			[
				this.state.tenants.map((tenant) => (
						<Menu.Item
							key={tenant.id}
							id={tenant.id}
							onClick={this.handleTenantSelected}
						>{tenant.name}</Menu.Item>
					)
				)
			]
		)
	}
}
