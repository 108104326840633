import {ApiService} from "./ApiService";

export class LookupService extends ApiService {

	getProgramInformation(success, failure) {
		this.fetch(`${this.uri}/api/lookup/program`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch(error => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getTimezones(success, failure) {
		this.fetch(`${this.uri}/api/lookup/timezones`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response.map((zone) => {
				return {'key': zone, 'value': zone, 'text': zone}
			})), 100);
		}).catch(error => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getTenants(success, failure) {
		this.fetch(`${this.uri}/api/tenants`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response.map((tenant) => {
				return {'key': tenant.id, 'value': tenant.id, 'text': tenant.name}
			})), 100);
		}).catch(error => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getRoles(success, failure) {
		this.fetch(`${this.uri}/api/lookup/roles`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response.map((role) => {
				return {'key': role.id, 'value': role.id, 'text': role.name + ' - ' + role.description}
			})), 100);
		}).catch(error => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getDeviceTypes(success, failure) {
		this.fetch(`${this.uri}/api/lookup/devicetypes`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response.map((devicetype) => {
				return {'key': devicetype.key, 'value': devicetype.key, 'text': devicetype.name}
			})), 100);
		}).catch(error => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getDataTypes(success, failure) {
		this.fetch(`${this.uri}/api/lookup/datatypes`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response.map((datatype) => {
				return {'key': datatype.key, 'value': datatype.key, 'text': datatype.name}
			})), 100);
		}).catch(error => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getTransformTypes(success, failure) {
		this.fetch(`${this.uri}/api/lookup/transformtypes`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response.map((transformtype) => {
				return {'key': transformtype.key, 'value': transformtype.key, 'text': transformtype.name, 'custom': transformtype.customParameters.toString()}
			})), 100);
		}).catch(error => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getTransformParameters(transformTypeId, success, failure) {
		this.fetch(`${this.uri}/api/lookup/transformtypes/${transformTypeId}/parameters`, {
			method: 'GET'
		}).then(response => {
			setTimeout(() => success(response), 100);
		}).catch(error => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getMetricTypes(success, failure) {
		this.fetch(`${this.uri}/api/lookup/metrictypes`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response.map((metrictype) => {
				return {'key': metrictype.key, 'value': metrictype.key, 'text': metrictype.name}
			})), 100);
		}).catch(error => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

}