import {AuthService} from './api/AuthService'
import {BoardService} from './api/BoardService'
import {DeviceService} from './api/DeviceService'
import {GraphService} from "./api/GraphService";
import {LookupService} from './api/LookupService'
import {MetricService} from './api/MetricService'
import {StationService} from './api/StationService'
import {TenantService} from './api/TenantService'
import {UserService} from './api/UserService'

export const authService = new AuthService();
export const boardService = new BoardService();
export const deviceService = new DeviceService();
export const graphService = new GraphService();
export const lookupService = new LookupService();
export const metricService = new MetricService();
export const stationService = new StationService();
export const tenantService = new TenantService();
export const userService = new UserService();
