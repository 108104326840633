import React from "react";

export class SeparatorMetricEditor extends React.Component {

	state = {
		id: this.props.metric.id || ''
	};

	constructor(...args) {
		super(...args);
		this.getStateToSave = this.getStateToSave.bind(this);
	}

	/* This has to be here to be a metric */
	getStateToSave() {
		return {}
	};

	render() {
		return (
			<div/>
		)
	}

}