import {ApiService} from "./ApiService";
import decode from 'jwt-decode';

const TOKEN_KEY = 'id_token';

// Found at https://hptechblogs.com/using-json-web-token-react/
export class AuthService extends ApiService {

	login(username, password, successCallback, failureCallback) {
		this.fetch(`${this.uri}/auth/login`, {
			method: 'POST',
			body: JSON.stringify({
				username,
				password
			})
		}).then(response => {
			this.setToken(response.token);
			setTimeout(successCallback, 100);
		}).catch((error) => {
			setTimeout(failureCallback(error.message), 100);
		});
	};

	loggedIn() {
		// Checks if there is a saved token and it's still valid
		const token = this.getToken();
		return token !== 'undefined' && !!token && !this.isTokenExpired(token);
	}

	isTokenExpired(token) {
		try {
			const decoded = decode(token);
			return decoded.exp < Date.now() / 1000;
		} catch (err) {
			return false;
		}
	}

	setToken(idToken) {
		// Saves user token to localStorage
		localStorage.setItem(TOKEN_KEY, idToken)
	}

	getToken() {
		// Retrieves the user token from localStorage
		return localStorage.getItem(TOKEN_KEY);
	}

	expire() {
		localStorage.removeItem(TOKEN_KEY);
	}

	reauthenticate() {
		window.location.replace('/login');
	}

	logout(callback) {
		this.expire();
		this.fetch(`${this.uri}/auth/logout`, {
			method: 'GET'
		}).then(response => {
			//this.props.history.push('/login');
			window.location.replace('/home');
			setTimeout(callback, 100);
		});
	}

	getProfile() {
		// Using jwt-decode npm package to decode the token
		return decode(this.getToken());
	}

}
