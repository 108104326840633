import React from 'react';
import {Button, Form, Grid, Header, Icon, Message, Modal} from 'semantic-ui-react'
import {boardService, lookupService, metricService} from "../Api";
import {CANCEL_ICON, DELETE_ICON, METRIC_ICON, SAVE_ICON} from '../config';
import {MetricType} from "../metric/MetricType";
import {SeparatorMetricEditor} from "./metric/SeparatorMetricEditor";
import {DeviceMetricEditor} from "./metric/DeviceMetricEditor";
import {GraphMetricEditor} from "./metric/GraphMetricEditor";

export default class MetricManagement extends React.Component {

	state = {
		editing: false,
		metric: {}
	};

	handleFormOpen = () => {
		this.props.onEditMetric(true);
		this.setState(
			{
				editing: true,
				metric: {
					id: '',
					name: '',
					type: {key: 'BASIC'},
					link: {},
					board: this.props.board,
					editor: '',
					editable: ''
				},
			}
		);
	};

	handleFormClose = () => {
		this.props.onEditMetric(false);
		this.setState({editing: false});
	};

	handleEditMetric = (metric) => {
		this.props.onEditMetric(true);
		this.setState({metric: metric, editing: true});
	};

	handleSaveMetric = () => {
		this.handleFormClose();
	};

	handleCancelMetric = () => {
		this.handleFormClose();
	};

	render() {
		if (!this.props.board.id) return (
			<div/>
		);

		if (this.state.editing) {
			return (
				<MetricForm metric={this.state.metric} onSaveMetric={this.handleSaveMetric} onCancelMetric={this.handleCancelMetric}/>
			);
		} else {
			return (
				<MetricList
					board={this.props.board}
					metrics={this.props.board.metrics}
					onNewMetric={this.handleFormOpen}
					onEditMetric={this.handleEditMetric}
				/>
			);
		}
	}

}

class MetricList extends React.Component {

	state = {
		metrics: []
	};

	componentDidMount() {
		this.loadMetrics();
		this.refreshTimer = setInterval(this.loadMetrics, 2000);
	}

	componentWillUnmount() {
		clearInterval(this.refreshTimer);
	}

	loadMetrics = () => {
		boardService.getMetrics(this.props.board.id, (metrics) => {
			//console.log(JSON.stringify(metrics));
			this.setState({metrics: metrics});
		}, (message) => {
			console.log(message)
		})
	};

	handleNewMetric = () => {
		this.props.onNewMetric()
	};

	handleEditMetric = (metric) => {
		this.props.onEditMetric(metric)
	};

	handleDeleteMetric = (id) => {
		metricService.deleteMetricById(id, () => {
			this.loadMetrics();
		}, (message) => {
			console.log(message);
		});
	};

	handleMoveMetric = (sourceIndex, targetIndex) => {
		boardService.moveMetric(this.props.board.id, sourceIndex, targetIndex, (metrics) => {
			this.setState({metrics: metrics})
		}, (message) => {
			console.log(message)
		})
	};

	render() {
		return (
			<Grid columns={3}>
				<Grid.Row>
					<Grid.Column width={16}>
						<Header>Metrics</Header>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						<Button icon='add' fluid onClick={this.handleNewMetric}/>
					</Grid.Column>
				</Grid.Row>
				{
					this.state.metrics.map((metric, index) => (
							// Probably because the key is the same, metric property changes are not propagated
							// Adding the name to the key fixed the issue, but what about other properties?
							// Do I need to create a state hash to be put in the key?
							// Using the map index also does not work because it does not change.
							<Metric key={metric.id} idx={index} metric={metric} onEditMetric={this.handleEditMetric} onDeleteMetric={this.handleDeleteMetric}
											onMoveMetric={this.handleMoveMetric}/>
						)
					)
				}
				<Grid.Row>
					<Grid.Column width={16}>
						<Button icon='add' fluid onClick={this.handleNewMetric}/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}

}

class Metric extends React.Component {

	state = {
		hover: false
	};

	handleEditMetric = () => this.props.onEditMetric(this.props.metric);

	handleDeleteMetric = () => this.props.onDeleteMetric(this.props.metric.id);

	handleDragStart = (event) => {
		event.dataTransfer.effectAllowed = "move";
		event.dataTransfer.setData("text/plain", this.props.idx);
	};

	handleDragEnter = (event) => {
		event.preventDefault();
		this.setState({hover: true});
	};

	handleDragOver = (event) => {
		event.preventDefault();
	};

	handleDragLeave = (event) => {
		this.setState({hover: false});
	};

	handleDrop = (event) => {
		event.preventDefault();
		this.setState({hover: false});
		let sourceIndex = parseInt(event.dataTransfer.getData("text/plain"), 10);
		let targetIndex = this.props.idx;
		if (sourceIndex === targetIndex) return;
		this.props.onMoveMetric(sourceIndex, targetIndex);
	};

	render() {
		return (
			<Grid.Row draggable onDragStart={this.handleDragStart} onDragEnter={this.handleDragEnter} onDragOver={this.handleDragOver}
								onDragLeave={this.handleDragLeave} onDrop={this.handleDrop}>
				<Grid.Column style={{cursor: 'pointer'}} onClick={this.handleEditMetric} width={4}>
					<Header><Icon name={METRIC_ICON}/>{this.props.metric.type.name}</Header>
				</Grid.Column>
				<Grid.Column style={{cursor: 'pointer'}} onClick={this.handleEditMetric} width={5}>
					<Header>{this.props.metric.name}</Header>
				</Grid.Column>

				<Grid.Column width={4} textAlign='right'>
					{this.props.metric.value}
				</Grid.Column>
				<Grid.Column width={2} textAlign='left'>
					{this.props.metric.unit}
				</Grid.Column>

				<Grid.Column textAlign='right' width={1}>
					<DeleteMetricDialog metric={this.props.metric} onDeleteMetric={this.handleDeleteMetric}/>
				</Grid.Column>
			</Grid.Row>
		)
	}

}

class DeleteMetricDialog extends React.Component {

	state = {open: false};

	handleOpen = () => this.setState({open: true});

	handleClose = () => this.setState({open: false});

	handleDelete = () => {
		this.handleClose();
		this.props.onDeleteMetric();
	};

	render() {
		return (
			<Modal size={'mini'} centered={false} open={this.state.open} onClose={this.handleClose}
						 trigger={<Icon name={DELETE_ICON} size={'large'} link onClick={this.handleOpen}/>}>
				<Modal.Header>Delete Metric</Modal.Header>
				<Modal.Content>
					<Header><Icon name={METRIC_ICON}/>{this.props.metric.name}</Header>
					<p>Are you sure you want to permanently delete this metric?</p>
				</Modal.Content>
				<Modal.Actions>
					<Button negative content={'No'} onClick={this.handleClose}/>
					<Button positive content={'Yes'} onClick={this.handleDelete}/>
				</Modal.Actions>
			</Modal>
		);
	}

}

export class MetricForm extends React.Component {

	state = {
		id: this.props.metric.id || '',
		type: (this.props.metric.type && this.props.metric.type.key) || '',
		name: this.props.metric.name || '',
		typeLookup: [],
		editingChild: false,
		errorMessage: '',
	};

	componentDidMount() {
		this.loadMetricTypes();
	}

	loadMetricTypes = () => {
		lookupService.getMetricTypes((metrictypes) => (
			this.setState({typeLookup: metrictypes})
		), (message) => {
			console.log(message);
		});
	};

	handleFieldChange = (event, {name, value}) => {
		// This method expects the component name to match the state key name
		this.setState({[name]: value});
	};

	handleNameChange = (name, matchWith) => {
		console.log("Change name to: " + name);
		const matches = matchWith && matchWith === this.state.name;
		if (this.state.name === '' || !matchWith || matches) this.setState({name: name});
	};

	checkForEnter = (event) => {
		if (event.key === 'Enter') this.handleSaveMetric();
	};

	handleSaveMetric = () => {
		this.doSaveMetric( false );
	};

	doSaveMetric = (editingChild=false) => {
		let metric = (this.editorRef.current.getStateToSave && this.editorRef.current.getStateToSave()) || {};
		metric.id = this.state.id;
		metric.name = this.state.name;
		metric.type = this.state.type;
		metric.board = {id: this.props.metric.board.id};

		const success = () => {
			if (!editingChild) this.props.onSaveMetric();
		};

		const failure = (message) => {
			this.setState({errorMessage: 'Failed to save metric configuration: ' + message})
		};

		//console.log(JSON.stringify(metric));
		if (metric.id === '') {
			this.handleCreateMetric(metric, success, failure)
		} else {
			this.handleUpdateMetric(metric, success, failure)
		}
	};

	handleCreateMetric = (metric, success, failure) => {
		metricService.newMetric(metric, () => {
			success();
		}, () => {
			failure();
		});
	};

	handleUpdateMetric = (metric, success, failure) => {
		//console.log(JSON.stringify(metric));
		metricService.saveMetric(metric, () => {
			success();
		}, () => {
			failure();
		});
	};

	handleCancelMetric = () => {
		this.props.onCancelMetric()
	};

	handleEditChild = (editingChild) => {
		this.setState({editingChild: editingChild});
		if (editingChild) this.doSaveMetric(editingChild);
	};

	determineMetricForm() {
		this.editorRef = React.createRef();
		switch (this.state.type) {
			case MetricType.BASIC : {
				return <DeviceMetricEditor ref={this.editorRef} metric={this.props.metric} onNameChange={this.handleNameChange}/>;
			}
			case MetricType.GRAPH : {
				return <GraphMetricEditor ref={this.editorRef} metric={this.props.metric} onEditChild={this.handleEditChild}/>;
			}
			case MetricType.SEPARATOR : {
				return <SeparatorMetricEditor ref={this.editorRef} metric={this.props.metric}/>;
			}
			default : {
				return <div>{this.state.type} Editor</div>;
			}
		}
	}

	render() {
		return (
			<Form error={this.state.errorMessage !== ''}>
				<Grid>
					<Grid.Row>
						<Grid.Column verticalAlign='middle'>
							<Icon name={METRIC_ICON} size='big' color='black'/>
						</Grid.Column>
						<Grid.Column verticalAlign='middle' width={13}>
							<Header>{this.state.name}</Header>
						</Grid.Column>
						<Grid.Column floated='right' textAlign='right' verticalAlign='middle'>
							{this.state.editingChild && <Icon name={SAVE_ICON} link size='big' color='grey' disabled/>}
							{!this.state.editingChild && <Icon name={SAVE_ICON} link size='big' color='green' onClick={this.handleSaveMetric}/>}
						</Grid.Column>
						<Grid.Column floated='right' textAlign='right' verticalAlign='middle'>
							<Icon name={CANCEL_ICON} link size='large' color='black' onClick={this.handleCancelMetric}/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Message error header={this.state.errorMessage}/>
							<Form.Group>
								<Form.Field name='type' width={8} control={Form.Select} required fluid label='Metric Type' placeholder='Type'
														value={this.state.type} options={this.state.typeLookup} onChange={this.handleFieldChange}/>
								<Form.Field name='name' width={8} control={Form.Input} fluid label='Metric Name' placeholder='Name' value={this.state.name}
														onChange={this.handleFieldChange} onKeyDown={this.checkForEnter}/>
							</Form.Group>

							{this.determineMetricForm()}

						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
		)
	}

}
