import {ApiService} from "./ApiService";

export class TenantService extends ApiService {

	getTenants(success, failure) {
		this.fetch(`${this.uri}/api/tenants`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getTenant(id, success, failure) {
		this.fetch(`${this.uri}/api/tenants/${id}`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	newTenant(tenant, success, failure) {
		this.fetch(`${this.uri}/api/tenants`, {
			method: 'POST',
			body: JSON.stringify(tenant)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	saveTenant(tenant, success, failure) {
		this.fetch(`${this.uri}/api/tenants`, {
			method: 'PUT',
			body: JSON.stringify(tenant)
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	deleteTenantById(id, success, failure) {
		this.fetch(`${this.uri}/api/tenants/${id}`, {
			method: 'DELETE'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

	getStations(tenantid, success, failure) {
		this.fetch(`${this.uri}/api/tenants/${tenantid}/stations`, {
			method: 'GET'
		}).then(response => {
			setTimeout(success(response), 100);
		}).catch((error) => {
			// Do I want to catch the error here or let it propagate???
			setTimeout(failure(error.message), 100);
		});
	}

}
