import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {Button, Container, Form, Input, Message, Select} from 'semantic-ui-react'
import {lookupService, userService} from './Api'
import {EMAIL_PATTERN, PHONE_PATTERN} from "./config";

export default class Profile extends Component {

	state = {
		redirectToReferrer: false,
		id: '',
		username: '',
		usernameError: false,
		name: '',
		nameError: false,
		phone: '',
		phoneError: false,
		email: '',
		emailError: false,
		alert: '',
		alertError: false,
		timezone: '',
		message: '',
		save: 'Save',
		timezones: []
	};

	componentDidMount() {
		this.loadUserProfile();
		this.loadTimezones();
	}

	loadUserProfile = () => {
		userService.getUserProfile((profile) => (
			this.setState({
				'id': profile.id,
				'username': profile.username,
				'name': profile.name,
				'phone': profile.phone == null ? "" : profile.phone,
				'email': profile.emailAddress == null ? "" : profile.emailAddress,
				'alert': profile.alertAddress == null ? "" : profile.alertAddress,
				'timezone': profile.timezone
			})
		), (message) => (
			console.log(message)
		));
	};

	loadTimezones = () => {
		lookupService.getTimezones((zones) => (
			this.setState({timezones: zones})
		));
	};

	handleNameChange = (event) => {
		this.setState({name: event.target.value, nameError: false});
	};

	handleUsernameChange = (event) => {
		this.setState({username: event.target.value, usernameError: false});
	};

	handleTimezoneChange = (event, {name, value}) => {
		this.setState({timezone: value});
	};

	handlePhoneChange = (event) => {
		let phoneError = PHONE_PATTERN.test(event.target.value);
		this.setState({phone: event.target.value, phoneError: phoneError});
	};

	handleEmailChange = (event) => {
		let emailError = !(event.target.value === "" || EMAIL_PATTERN.test(event.target.value));
		this.setState({email: event.target.value, emailError: emailError});
	};

	handleAlertChange = (event) => {
		let alertError = !(event.target.value === "" || EMAIL_PATTERN.test(event.target.value));
		this.setState({alert: event.target.value, alertError: alertError});
	};

	handleSave = () => {
		this.setState({save: 'Saving...'});

		const profile = {
			id: this.state.id,
			username: this.state.username,
			name: this.state.name,
			timezone: this.state.timezone,
			phone: this.state.phone,
			emailAddress: this.state.email,
			alertAddress: this.state.alert
		};

		userService.saveProfile(profile, () => {
			this.setState({redirectToReferrer: true});
		}, () => {
			this.setState({save: 'Save', message: 'Failed to save user profile'});
		});
	};

	handleCancel = () => {
		this.setState({redirectToReferrer: true})
	};

	render() {
		if (this.state.redirectToReferrer) return <Redirect to={this.props.from}/>;

		const errorMessage = this.state.message;

		return (
			<Container>
				<Form error={errorMessage !== ''}>
					<Message error header={errorMessage}/>
					<Form.Field id='username' control={Input} required fluid placeholder='Username' label='Username' value={this.state.username} onChange={this.handleUsernameChange}/>
					<Form.Field id='name' control={Input} required fluid placeholder='Name' label='Name' value={this.state.name} onChange={this.handleNameChange}/>
					<Form.Field id='phone' control={Input} fluid placeholder='Phone' label='Phone' value={this.state.phone} onChange={this.handlePhoneChange} error={this.state.phoneError}/>
					<Form.Field id='email' control={Input} required fluid placeholder='Contact Email Address' label='Contact Email' value={this.state.email} onChange={this.handleEmailChange} error={this.state.emailError}/>
					<Form.Field id='alert' control={Input} fluid placeholder='Alert Email Address' label='Alert Email' value={this.state.alert} onChange={this.handleAlertChange} error={this.state.alertError}/>
					<Form.Field id='timezone' control={Select} search required fluid placeholder='Time Zone' label='Time Zone' value={this.state.timezone} options={this.state.timezones} onChange={this.handleTimezoneChange}/>
					<Container textAlign='center'>
						<Button.Group>
							<Button positive onClick={this.handleSave}>{this.state.save}</Button>
							<Button negative onClick={this.handleCancel}>Cancel</Button>
						</Button.Group>
					</Container>
				</Form>
			</Container>
		);
	};

}
