import React, {Component} from 'react'
import {Label, Menu} from 'semantic-ui-react'
import {ANIMATION_DURATION} from '../config'
import {authService} from "../Api";

export default class UserMenu extends Component {

	handleUserProfile = () => {
		setTimeout(this.props.history.push, ANIMATION_DURATION, '/profile');
		this.props.onMenuClick();
	};

	handleUserPassword = () => {
		setTimeout(this.props.history.push, ANIMATION_DURATION, '/password');
		this.props.onMenuClick();
	};

	handleUserLogout = () => {
		authService.logout();
	};

	// handleClearCache = () => {
	// 	let clearCache =
	// 		<ClearCache>
	// 			{({isLatestVersion, emptyCacheStorage}) => (
	// 				<div>
	// 					{!isLatestVersion && (
	// 						<p>
	// 							<a href="#" onClick={e => {
	// 								e.preventDefault();
	// 								emptyCacheStorage();
	// 							}}
	// 							>Update version</a>
	// 						</p>
	// 					)}
	// 				</div>
	// 			)}
	// 		</ClearCache>
	// }

	render() {
		// let clearCache = <ClearCache>
		// 	{({isLatestVersion, emptyCacheStorage}) => (
		// 		// <span>
		// 		// 	{!isLatestVersion && (
		// 				<Menu.Item key='clear-cache' onClick={emptyCacheStorage}>
		// 					Force Update
		// 				</Menu.Item>
		// 		// 	)}
		// 		// </span>
		// 	)}
		// </ClearCache>

		let items = [
			<Menu.Item key='user-profile' onClick={this.handleUserProfile}>Profile<Label>{this.props.user.name}</Label></Menu.Item>,
			<Menu.Item key='user-password' onClick={this.handleUserPassword}>Change Password</Menu.Item>,
			<Menu.Item key='spacer'/>,
			<Menu.Item key='user-logout' onClick={this.handleUserLogout}>Logout</Menu.Item>,
			// <Menu.Item key='clear-cache' onClick={this.emptyCacheStorage}>Force Update</Menu.Item>
			// clearCache
		];

		return (
			items
		);
	}
}
